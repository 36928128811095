import React, { useEffect, useRef, useState } from "react";
import RequestApi from "../../RequestApi";
import DemoSideMenu from "../../common/sideMenu/DemoSideMenu";
import { Link , useHistory } from "react-router-dom";
import ResponseInfo from "../../common/response/ResponseInfo";
import SyntaxHighlighter from "react-syntax-highlighter";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import Loader from 'react-loader-spinner'
import Loading from '../../loading';
const GolfSwingVideoComponent = (match) => {
  const history = useHistory();
  const [state, setState] = useState(undefined);
  const [slide, setSlide] = useState(true);
  const [getResultData, setResultData] = useState(undefined);
  const [canvasWidth, setCanvasWidth] = useState(undefined);
  const body = document.querySelector("body");
  const [requestData, setRequestData] = useState({
    header: {
      appKey: "",
      "Content-Type": "application/json",
    },
    body: { file: undefined },
  });
  const [metaData, setMataData] = useState(undefined);
  const [httpResult, setHttpResult] = useState(undefined);
  const [loading, setLoading] = useState(undefined);
  const inputFileTag = useRef(null);
  const inputTextTag = useRef(null);
  const videoCanvasTag = useRef(null);
  const imgCanvasTag = useRef(null);
  

  useEffect(() => {
    if(document.querySelector(".lnb").style.display === 'block'){
      body.style.overflow = "hidden";
    }else{
      body.style.overflow = "";
    }
    a11yDark["hljs"].height = "100%";
  });
  let video = undefined;
  let img = undefined;
  let videoCtx = undefined;
  let imgCtx = undefined;
  let ctx = [];
  let initFlag = true;
  let guidePoint = undefined;
  let guideType = "";
  const path = match.match.path;

  async function analzer() { //line 41~99 이미지를 API에 요청해서 좌표값 받아오는 부분
    if(document.getElementById("appKey").value === ''){
      alert('appKey를 입력해 주십시오.');
      return false;
    }else if(document.getElementById("file_name").value === ''){
      alert('파일을 업로드해 주십시오.');
      return false;
    }else if(document.querySelector('input[name="imgType"]:checked') === null){
      alert('포지션을 선택해 주십시오');
      return false;
    }else{
    const file = inputFileTag.current.files[0];
    const apiKey = inputTextTag.current.value;
    // const myHeaders = new Headers();
    // myHeaders.append("Authorization", "skt123skt"); //apiKey
    setState(undefined);
    setHttpResult(undefined);
    setLoading(true);
    if(guideType === ""){
      guideType = document.querySelector('input[name="imgType"]:checked').value;
    }
    const guideline = await RequestApi(
      "Guideline",
      "CreateAnalyzer",
      guideType,
      apiKey, //apiKey
      file,
      "/golf/v1/create_analyzer",
      "/golf/v1/get_result/"
    );
    const { getResult, result, createAnalyzer } = guideline;
    // console.info(getResult);
    setState(result.state);
    setHttpResult(guideline);
    console.info(guideline);
    if (result.type === "createAnalyzer") {
      setMataData(JSON.stringify(createAnalyzer, null, "  "));
    } else {
      setMataData(JSON.stringify(getResult.Guideline, null, "  "));
    }
    setRequestData({
      baseUrl: process.env.REACT_APP_API_URL,
      header: {
        appKey: apiKey,
        "Content-Type": "application/json",
      },
      body: {
        file: file.name,
        Type: "Guideline",
        Action: "CreateAnalyzer",
        Swing: guideType,
      },
    });
    setResultData({
      baseUrl: process.env.REACT_APP_API_URL+"/golf/v1/get_result/"+createAnalyzer.JobID,
      header: {
        appKey: apiKey
      },      
      method:"GET" 
    });
    
    if (getResult.State === "Success") {
      guidePoint = getResult.Guideline;
      setCanvasWidth(imgCanvasTag.current.width)
      const videoCanvas = videoCanvasTag.current; //document.querySelector(".canvas");
      const imgCanvas = imgCanvasTag.current;
      videoCtx = videoCanvas.getContext("2d");
      imgCtx = imgCanvas.getContext("2d");

      ctx.push(videoCtx, imgCtx);
      video = document.createElement("video");
      video.src = getResult.URL;
      video.muted = true;
      video.autoplay = true;
      video.loop = true;
      video.play();
      img = document.createElement("video");
      img.src = getResult.URL;
      img.muted = true;
      img.autoplay = true;
      // video.loop = true;
      // img.play();
      video.addEventListener("canplaythrough", render);  //  canvas로 이미지, 영상 표출 후 받아온 좌표를 이용해 포인트와 라인을 그리는 함수 호출
    }
  }
  setLoading(false);
}
  function render() { //line 100~210 canvas로 이미지, 영상 표출 후 받아온 좌표를 이용해 포인트와 라인을 그리는 함수
    if (initFlag) {
      videoCanvasTag.current.height = video.videoHeight;
      videoCanvasTag.current.width = video.videoWidth;
      imgCanvasTag.current.height = video.videoHeight;
      imgCanvasTag.current.width = video.videoWidth;
      initFlag = false;
    }

    videoCtx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
    imgCtx.drawImage(img, 0, 0, video.videoWidth, video.videoHeight);
    // 머리
    ctx.forEach((head) => {
      head.fillStyle = "blue";
      head.beginPath();
      if(guidePoint.head_center[0] > 0 && guidePoint.head_center[1] > 0 && guidePoint.head_circle_radius > 0){
        head.arc(
          guidePoint.head_center[0],
          guidePoint.head_center[1],
          guidePoint.head_circle_radius,
          0,
          2 * Math.PI
        ); //head
        head.closePath();
        head.stroke();
      }
    });
    videoCtx.fillStyle = "red";
    imgCtx.fillStyle = "red";
    // guidePoint.joints.forEach((joint) => {
    //   //joint
    //   videoCtx.fillRect(joint[0], joint[1], 5, 5);
    //   imgCtx.fillRect(joint[0], joint[1], 5, 5);
    // });

    if (guideType === "Full_Front") {
      if(guidePoint.body_line_left_top_point[0] > 1 || guidePoint.body_line_left_bottom_point[1] > 1){
        drawGuideline(
          guidePoint.body_line_left_top_point,
          guidePoint.body_line_left_bottom_point,
          "green",
          "8"
        ); //body left
      }
      if(guidePoint.body_line_right_top_point[0] > 1 || guidePoint.body_line_right_bottom_point[1] > 1){
        drawGuideline(
          guidePoint.body_line_right_top_point,
          guidePoint.body_line_right_bottom_point,
          "green",
          "8"
        ); // body right
      }
      if(guidePoint.shoulder_line_left_point[0] > 1 || guidePoint.shoulder_line_right_point[1] > 1){
        drawGuideline(
          guidePoint.shoulder_line_left_point,
          guidePoint.shoulder_line_right_point,
          "blue",
          "8"
        ); // shoulder
      }
      if(guidePoint.hip_ankle_line_lefttop_point[0] > 1 || guidePoint.hip_ankle_line_leftbottom_point[1] > 1){
        drawGuideline(
          guidePoint.hip_ankle_line_lefttop_point,
          guidePoint.hip_ankle_line_leftbottom_point,
          "blue",
          "8"
        ); // ankle left
      }
      if(guidePoint.hip_ankle_line_rightbottom_point[0] > 1 || guidePoint.hip_ankle_line_rightbottom_point[1] > 1){
        drawGuideline(
          guidePoint.hip_ankle_line_righttop_point,
          guidePoint.hip_ankle_line_rightbottom_point,
          "blue",
          "8"
        ); // ankle right 
      }
    } else {
      if(guidePoint.torso_start_point[0] > 1 || guidePoint.torso_end_point[1] > 1){
        drawGuideline(
          guidePoint.torso_start_point,
          guidePoint.torso_end_point,
          "green",
          "8"
        ); // ankle right
      }
      if(guidePoint.upleg_start_point[0] > 1 || guidePoint.upleg_end_point[1] > 1){
        drawGuideline(
          guidePoint.upleg_start_point,
          guidePoint.upleg_end_point,
          "orange",
          "8"
        ); // ankle right
      }
      if(guidePoint.lowleg_start_point[0] > 1 || guidePoint.lowleg_end_point[1] > 1){
        drawGuideline(
          guidePoint.lowleg_start_point,
          guidePoint.lowleg_end_point,
          "blue",
          "8"
        ); // ankle right
      }
      if(guidePoint.neck_ball_line_start_points[0] > 1 || guidePoint.neck_ball_line_end_points[1] > 1){
        drawGuideline(
          guidePoint.neck_ball_line_start_points,
          guidePoint.neck_ball_line_end_points,
          "blue",
          "8"
        ); // ankle right
      }
      if(guidePoint.hip_ball_line_start_points[0] > 1 || guidePoint.hip_ball_line_end_points[1] > 1){
        drawGuideline(
          guidePoint.hip_ball_line_start_points,
          guidePoint.hip_ball_line_end_points,
          "red",
          "8"
        ); // ankle right
      }
      if(guidePoint.torso_start_point[0] > 1 || guidePoint.ball_center[1] > 1){
        drawBallLine(
          guidePoint.torso_start_point,
          guidePoint.ball_center,
          "yellow",
          8
        );
      }
      if(guidePoint.torso_end_point[0] > 1 || guidePoint.ball_center[1] > 1){
        drawBallLine(
          guidePoint.torso_end_point,
          guidePoint.ball_center,
          "yellow",
          8
        );
      }
    }

    requestAnimationFrame(render); //영상 재생
  }

  function drawGuideline(startPoint, endPoint, color, lineWidth) {
    // 곡선 , 원 , 포인트를 그리는 함수
    ctx.forEach((line) => {
      line.fillRect(startPoint[0], startPoint[1], 10, 10);
      line.fillRect(endPoint[0], endPoint[1], 10, 10);
      line.strokeStyle = color;
      line.lineWidth = lineWidth;
      line.beginPath();
      line.moveTo(startPoint[0], startPoint[1]);
      line.lineTo(endPoint[0], endPoint[1]);
      line.closePath();
      line.stroke();
    });
  }

  function drawBallLine(startPoint, ballPoint, color, lineWidth) {
    //full_back 일때만 공이랑 상체랑 연결 하는 함수
    if (ballPoint[0] !== -1 || ballPoint[1] !== -1){
      ctx.forEach((line) => {
        line.fillRect(startPoint[0], startPoint[1], 10, 10);
        line.fillRect(ballPoint[0], ballPoint[1], 10, 10);
        line.strokeStyle = color;
        line.lineWidth = lineWidth;
        line.beginPath();
        line.moveTo(startPoint[0], startPoint[1]);
        line.lineTo(ballPoint[0], ballPoint[1]);
        line.closePath();
        line.stroke();
      });
    }
  }
  function radioClear(value){
    document.getElementById('imgType_1').checked = false
    document.getElementById('imgType_2').checked = false
    // setState(undefined);
    // setHttpResult(undefined);
    if(value === undefined){
      //document.getElementById("file_name").value = "";
    }else{
      document.getElementById("file_name").value = value.name;
    }
  }
  return (
    <section className="container sub_container">
      <button
        className="btn_lnb_open"
        onClick={() => {
          if (slide) {
            document.querySelector(".lnb").style.display = "block";
            document.querySelector(".btn_lnb_open").classList.add("on")
            setSlide(false);
            body.style.overflow = "hidden";
          } else {
            document.querySelector(".lnb").style.display = "none";
            document.querySelector(".btn_lnb_open").classList.remove("on")
            setSlide(true);
            body.style.overflow = "";
          }
        }}
      >
        Golf Swing Analyzer
      </button>
      <DemoSideMenu path={path}></DemoSideMenu>
      <div className="subContent">
        <div className="sub_top_title">
          <div className="mb_24">
            <h1>Golf Swing Analyzer</h1>
            <div className="right_btn">
              <Link to="/golfDocument" className="btn">
                API Document
              </Link>
            </div>
          </div>

          <p className="txtType_1">
            SKT Pose API 를 이용하여 실내 스크린 골프
            존 내 스윙 영상을 분석해서 골프 스윙 준비 자세를 추출하고, 준비
            자세가 알맞도록 사람의 머리, 관절, 골프 공 등을 추출해 Guideline 을
            제공합니다.
          </p>
          <div className="tooltip">
            최대 용량 10MB / 해상도 640 ~ 1024 pixel인 영상만 분석이 가능하며,
            4초 이상의 영상에 대한 초기 4초의 한번 스윙에 대해 분석이 가능합니다.
            또한 스크린 골프장 영상에 적합하게 설계되어,
            야외 영상 및 한 명 기준의 골프 스윙이 아닌 영상,
            회전된 영상 등은 준비 자세가 제대로 추출되지 않을 수 있습니다.
            mp4, mov 확장자 이외의 영상은 결과가 제대로 추출되지 않을 수 있습니다.
          </div>
        </div>

        <h2 className="titleType_1">요청</h2>
        <div className="listType_4">
          <ul>
            <li>
              <label htmlFor="appKey" className="title">
                appKey
              </label>
              <input type="text" id="appKey" ref={inputTextTag} />
            </li>
            <li>
              <span className="title">File</span>
              <div className="right_btn">
                <input type="text" id="file_name" disabled/>
                <input type="file" id="file_upload" accept=".mp4, .mkv, .gif, .avi " style={{ display: "none" }} ref={inputFileTag} onChange={(e)=> radioClear(e.target.files[0])}/>
                <label htmlFor="file_upload" className="btn">
                  Upload
                </label>
              </div>
            </li>
            <li>
              <span className="title">Image</span>
              <span className="custom_radio">
                <input
                  value="Full_Front"
                  type="radio"
                  id="imgType_1"
                  name="imgType"
                  onChange={(event) => {
                    guideType = event.target.value;
                  }}
                />
                <label htmlFor="imgType_1">Front</label>
              </span>
              <span className="custom_radio">
                <input
                  value="Full_Back"
                  type="radio"
                  id="imgType_2"
                  name="imgType"
                  onChange={(event) => {
                    guideType = event.target.value;
                  }}
                />
                <label htmlFor="imgType_2">Back</label>
              </span>
            </li>
          </ul>
        </div>
        <div className="txt_r">
          <input
            type="submit"
            value="전송"
            className="basic-btn01 btn-red-bg"
            onClick={analzer}
          />
        </div>
      </div>
      {state === undefined ? (
        <></>
      ) : state === "Success" ? (
        <div className="sub_box">
          <div className="title_area">
            <h2>응답</h2>
          </div>
          <div className="sub_box_content">
            <h3 className="titleType_2">분석 결과</h3>
            <div className="img_list">
              <ul>
                <li>
                  <div className="img_area">
                    {/* <img src="../img/img_golf_video_p.jpg" alt="" /> */}
                    <canvas
                      ref={imgCanvasTag}
                      style={{ width: canvasWidth }}
                      className="canvas"
                    ></canvas>
                  </div>
                  <div className="txtArea">분석 이미지</div>
                </li>
                <li>
                  <div className="img_area">
                    {/* <img src="../img/img_golf_image_p.jpg" alt="" /> */}
                    <canvas
                      ref={videoCanvasTag}
                      style={{ width: canvasWidth }}
                      className="canvas"
                    ></canvas>
                  </div>
                  <div className="txtArea">분석 영상</div>
                </li>
              </ul>
            </div>

            <h3 className="titleType_4">Meta in json</h3>
            <div className="codeArea">
              <SyntaxHighlighter
                language="json"
                style={a11yDark}
                showLineNumbers
              >
                {`${metaData}`}
              </SyntaxHighlighter>
            </div>
          </div>
        </div>
      ) : (
        <div className="sub_box">
          <div className="title_area">
            <h2>응답</h2>
          </div>
          <div className="sub_box_content">
            <h3 className="titleType_2">분석 결과</h3>

            <div className="analysis_result">
              <div className="no_result">
                <p className="error_txt">
                  동영상이 분석되지 않았습니다. <br />
                  다시 시도해 주십시오.
                  <span>(Response Code : 500)</span>
                </p>
              </div>
            </div>

            
            <div className="top_right_btn_area">
              <h4 className="titleType_2">Meta in json</h4>
            </div>
            <div className="codeArea">
              <SyntaxHighlighter
                language="json"
                style={a11yDark}
                showLineNumbers
                wrapLongLines
              >
                {`${metaData}`}
              </SyntaxHighlighter>
            </div>
          </div>
        </div>
      )}
      <ResponseInfo
        httpResult={httpResult}
        requestData={requestData}
        getResultData={getResultData}
        type="golf"
      ></ResponseInfo>
      <div className="loading">{loading? <Loading></Loading> : ""}</div>
    </section>
  );
};

export default GolfSwingVideoComponent;
