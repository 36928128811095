import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import DocumentSideMenu from "../../common/sideMenu/DocumentSideMenu";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import SyntaxHighlighter from "react-syntax-highlighter";
const PoseDocument = (match) => {
  const path = match.match.path;
  const [state, setState] = useState(undefined);
  const [slide, setSlide] = useState(true);
  const body = document.querySelector("body");
  useEffect(() => {
    if(document.querySelector(".lnb").style.display === 'block'){
      body.style.overflow = "hidden";
    }else{
      body.style.overflow = "";
    }
  });
  return (
    <section className="container sub_container">
      <button
        className="btn_lnb_open"
        onClick={() => {
          if (slide) {
            document.querySelector(".lnb").style.display = "block";
            document.querySelector(".btn_lnb_open").classList.add("on")
            setSlide(false);
            body.style.overflow = "hidden";
          } else {
            document.querySelector(".lnb").style.display = "none";
            document.querySelector(".btn_lnb_open").classList.remove("on")
            setSlide(true);
            body.style.overflow = "";
          }
        }}
      >Pose Estimation</button>
      <DocumentSideMenu
        path={path}
        routeLink="/PoseDocument"
      ></DocumentSideMenu>
      <div className="subContent">
        <div className="sub_top_title">
          <h1>Pose Estimation</h1>
          <div className="right_btn">
            <Link to="/poseEstimationVideo" className="btn">
              API Demo
            </Link>
          </div>
        </div>
        <p className="txtType_1 mb_50">
          영상을 분석해 사람의 머리, 눈, 코, 손목, 무릎 등 25개의 관절 포인트를 추출하는 기능을 제공합니다.
        </p>

        <h2 className="titleType_1">제공기능</h2>
        <div className="listType_1">
          <ul>
            <li>
              <div className="title">
                <span>create_analyzer</span>
              </div>
              <div className="txt">
                <div className="inner">
                  관절 포인트 분석을 위해 영상을 업로드 할 수 있는 URL 과
                  Job ID를 반환하는 API
                </div>
              </div>
            </li>
            <li>
              <div className="title">
                <span>start_analyzer</span>
              </div>
              <div className="txt">
                <div className="inner">
                  영상을 다중 이미지화하여 이미지에서 사람을
                  탐색하여 관절 포인트를 분석하고 작업물의 처리상태를 반환하는
                  API
                </div>
              </div>
            </li>
            <li>
              <div className="title">
                <span>get_result</span>
              </div>
              <div className="txt">
                <div className="inner">
                  영상분석하기(start_analyzer) API에서 분석된 결과물을 json
                  형태로 반환하는 API
                </div>
              </div>
            </li>
            <li>
              <div className="title">
                <span>e2e</span>
              </div>
              <div className="txt">
                <div className="inner">
                  HTTP Protocol로 3MB 이하의 파일 업로드, 영상분석을 한번에
                  처리하여 JSON 형태로 반환하는 API
                </div>
              </div>
            </li>
          </ul>
        </div>

        <h2 className="titleType_1">주의사항</h2>
        <div className="listType_2 mb_40">
          <ul>
            <li>
              최대 10MB 용량 또는 5초 이내의 영상 분석이 가능합니다.
            </li>
            <li>
              회전된 영상, 정면이 아닌 영상은 자세가 제대로 추출되지 않을 수 있습니다.
            </li>
          </ul>
        </div>

        <h2 className="titleType_1">개발가이드</h2>
        <p className="txtType_1">
          Pose Estimation API는 SDK를 지원하지 않으며 HTTP API 방식으로 구현할
          수 있습니다.
        </p>
        <div className="listType_2 mb_40">
          <ul>
            <li>
              요청 시 HTTP Connection Timeout은 5초, read TimeOut 은 120초로
              설정합니다.
            </li>
            <li>
              과도하게 많은 요청을 보내어 초당 요청 한도를 초과하는 경우 오류
              응답을 받을 수 있습니다.
            </li>
            <li>
              Create_analyzer API 요청 시 Response 이후 다음 API 호출을 위한
              최대 시간은 15분입니다.
            </li>
            <li>
              영상 해상도는 최소 640 pixel, 최대 1024 pixel에 최적화되어 이외에는 결과가 제대로 추출되지 않을 수 있습니다. 
            </li>
            <li>
              mp4, mov 확장자 이외의 영상은 결과가 제대로 추출되지 않을 수 있습니다.
            </li>
          </ul>
        </div>

        

        <h2 className="titleType_1">영상 업로드 (create_analyzer API)</h2>
        <p className="txtType_1">
          업로드 요청 시 영상을 업로드 할 수 있는 URL을 반환합니다.
        </p>
        <div className="listType_2">
          <ul>
            <li>
              URL : /sigmeta/pose/v1/create_analyzer 
            </li>
            <li>Method : POST</li>
            <li>Request</li>
          </ul>
        </div>

        <h3 className="titleType_2">Header</h3>
        <div className="tableType_2">
          <table>
            <colgroup>
              <col style={{ width: "137px" }} />
              <col style={{ width: "96px" }} />
              <col style={{ width: "490px" }} />
              <col style={{ width: "154px" }} />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Type</th>
                <th scope="col">Description</th>
                <th scope="col">Required</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Content-type</td>
                <td>String</td>
                <td>application/json</td>
                <td>Required</td>
              </tr>
              <tr>
                <td>App Key</td>
                <td>String</td>
                <td>
                  SK Open API의 App Key <br />
                  My Project > Key > Project Key (App Key)
                </td>
                <td>
                  Required <br />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <h3 className="titleType_2">Parameter</h3>
        <div className="tableType_2">
          <table>
            <colgroup>
              <col style={{ width: "137px" }} />
              <col style={{ width: "154px" }} />
              <col style={{ width: "95px" }} />
              <col style={{ width: "338px" }} />
              <col style={{ width: "154px" }} />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">Key</th>
                <th scope="col">Value</th>
                <th scope="col">Type</th>
                <th scope="col">Description</th>
                <th scope="col">Required</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Type</td>
                <td>PoseEstimation</td>
                <td>String</td>
                <td>"PoseEstimation"</td>
                <td>Required</td>
              </tr>
              <tr>
                <td>Action</td>
                <td>CreateAnalyzer</td>
                <td>String</td>
                <td>"CreateAnalyzer"</td>
                <td>Required</td>
              </tr>
              <tr>
                  <td>Swing</td>
                  <td>Full_Front</td>
                  <td>String</td>
                  <td>"Full_Front"</td>
                  <td>Required</td>
              </tr>
            </tbody>
          </table>
        </div>

        <h3 className="titleType_2">Request Body Example</h3>
        <div className="ex_code">
              <SyntaxHighlighter
                language="json"
                style={a11yDark}
                showLineNumbers
              >
                {`GET https://apis.openapi.sk.com/sigmeta/golf/v1/create_analyzer
headers: {
    "content-type": "application/json",
    "appKey": appKey
    },
body: json {
    "Type": "Guideline",
    "Action": "CreateAnalyzer",
    "Swing": "Full_Front"
    }`}
              </SyntaxHighlighter>
        </div>

        <h4 className="titleType_3">Response</h4>
        <div className="tableType_2">
          <table>
            <colgroup>
              <col style={{ width: "137px" }} />
              <col style={{ width: "96px" }} />
              <col style={{ width: "647px" }} />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Type</th>
                <th scope="col">Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>JobID</td>
                <td>Int</td>
                <td>부여된 Job ID</td>
              </tr>
              <tr>
                <td>State</td>
                <td>String</td>
                <td>“Success” or “Fail”</td>
              </tr>
              <tr>
                <td>Status</td>
                <td>String</td>
                <td>“Created”, "NotCreated", "Analyzing", "AllDone" or “Failed”</td>
              </tr>
              <tr>
                <td>URL</td>
                <td>String</td>
                <td>파일 올릴 수 있는 S3 임시 URL</td>
              </tr>
              <tr>
                <td>From</td>
                <td>String</td>
                <td>
                  파일 업로드를 위한 S3 Bucket 정보 <br />
                  [key, policy, x-amz-algorithm, x-amz-credential, x-amz-date,
                  <br />
                  x-amz-security-token, x-amz-signature]
                </td>
              </tr>
              <tr>
                        <td>Error</td>
                        <td>String</td>
                        <td>Status Fail 시 Error 내용</td>
              </tr>
            </tbody>
          </table>
        </div>

        <h3 className="titleType_2">Success Response Example</h3>
        <div className="ex_code">
        <SyntaxHighlighter
                language="json"
                style={a11yDark}
                showLineNumbers
              >
                {`JobID: "99708869-926b-4be0-ad5f-f9e02bfb4e92", State: "Success", Status: "Created", URL:
"https://hivevisionprod-golf-data-ap-northeast-2-40524.s3.amazonaws.com/", Form: {…}}
Form:
key: "input/PoseEstimation/Full_Front/2021/7/8/2b4824a5-2110-43b4-827d-c55538e796c3.mp4"
policy: "eyJleHBpcmF0aW9uIjogIjIwMjEt…=”
x-amz-algorithm: "AWS4-HMAC-SHA256"
x-amz-credential: "ASIAYL6RXC3H2QVVIO6T/20210708/ap-northeast-2/s3/aws4_request"
x-amz-date: "20210708T062608Z"
x-amz-security-token: "IQoJb3JpZ2luX2VjEIb///="
x-amz-signature: "36012db607d9a390b3940d8162999826036b503eb65aa78a69a918c46e391f0c"`}
              </SyntaxHighlighter>
        </div>

        <h2 className="titleType_1">영상 분석하기 (start_analyzer API)</h2>
        <p className="txtType_1">
          입력된 영상을 다중 이미지화하여 이미지에서 한 명을 인식하여 사람의 코,
          눈 귀, 어깨, 팔꿈치, 손목, 골반, 무릎, 발목 총 25개의 키 포인트를
          추출하여 사람의 자세를 분석합니다.
        </p>
        <div className="listType_2">
          <ul>
            <li>URL : {'{URL}'} </li>
            <li>Method : POST</li>
            <li>Request</li>
          </ul>
        </div>
        <div className="tableType_2">
          <table>
            <colgroup>
              <col style={{ width: "137px" }} />
              <col style={{ width: "96px" }} />
              <col style={{ width: "647px" }} />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Type</th>
                <th scope="col">Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Form</td>
                <td>String</td>
                <td>
                  파일 업로드를 위한 S3 Bucket 정보 (CreateAnalyzer 요청 시
                  받음) <br />
                  [key, policy, x-amz-algorithm, x-amz-credential, x-amz-date, x-amz-security-token, x-amz-signature]
                </td>
              </tr>
              <tr>
                <td>File</td>
                <td>File</td>
                <td>
                  동영상 파일 <br />
                  지원 Codec : H.264, MPEG<br />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <h2 className="titleType_1">분석 결과 확인하기 (get_result API)</h2>
        <div className="txtType_1">
          영상분석하기(start_analyzer) API를 통해 요청한 작업물의 결과값을 JSON
          형태로 반환하는 API입니다. 영상 업로드 요청 시 전달받은 JobID를 URL에
          담고 GET으로 영상 포즈 분석 결과를 확인합니다. 영상 분석이 성공적으로
          완료되면 골프 스윙 준비 자세 프레임과 해당 프레임에서 가장 큰 사람의
          관절 좌표가 JSON 객체로 반환합니다.
        </div>
        <div className="listType_2">
          <ul>
            <li>
              URL : /sigmeta/pose/v1/get_result/:JobID
            </li>
            <li>Method : GET</li>
            <li>Response</li>
          </ul>
        </div>

        <h3 className="titleType_2">Header</h3>
        <div className="tableType_2">
          <table>
            <colgroup>
              <col style={{ width: "137px" }} />
              <col style={{ width: "96px" }} />
              <col style={{ width: "206px" }} />
              <col style={{ width: "440px" }} />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Type</th>
                <th scope="col">Description</th>
                <th scope="col">Required</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>appKey</td>
                <td>String</td>
                <td>SK Open API의 App Key</td>
                <td>Required </td>
              </tr>
            </tbody>
          </table>
        </div>

        <h3 className="titleType_3">Response</h3>
        <div className="tableType_2">
          <table>
            <colgroup>
              <col style={{ width: "137px" }} />
              <col style={{ width: "96px" }} />
              <col style={{ width: "647px" }} />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Type</th>
                <th scope="col">Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>JobID</td>
                <td>String</td>
                <td>해당 분석의 Job ID</td>
              </tr>
              <tr>
                <td>State</td>
                <td>String</td>
                <td>“Success” or “Fail”</td>
              </tr>
              <tr>
                <td>Status</td>
                <td>String</td>
                <td>“Create”, “NotCreated”, “Analyzing”, “AllDone” or “Failed”</td>
              </tr>
              <tr>
                <td>List of joints</td>
                <td>Array[]</td>
                <td>Frame number별 Joints </td>
              </tr>
              <tr>
                <td>Joints</td>
                <td>Array[]</td>
                <td>
                  25개의 관절 좌표 <br />
                  >추출되지 않았을 경우 -1 로 반환 <br />
                  [nose, neck, right shoulder, right elbow, right wrist, left
                  shoulder, left elbow, left wrist, pelvis, right hip, right
                  knee, right ankle, left hip, left knee, left ankle, right eye,
                  left eye, right ear, left ear, left big toe, left small toe,
                  left heel, right big toe, right small toe, right heel]
                </td>
              </tr>
              <tr>
                <td>url</td>
                <td>String</td>
                <td>Meta가 저장되어 있는 URL</td>
              </tr>
              <tr>
                        <td>Error</td>
                        <td>String</td>
                        <td>Status Fail 시 Error 내용</td>
              </tr>
            </tbody>
          </table>
        </div>

        <h3 className="titleType_2">Request Body Example</h3>
        <div className="ex_code">
          <SyntaxHighlighter
                language="json"
                style={a11yDark}
                showLineNumbers
              >
                {`POST https://apis.openapi.sk.com/sigmeta/pose/v1/get_result/99708869-926b-4be0-ad5f-f9e02bfb4e92
headers: {
  "appKey": appKey
  }`}
              </SyntaxHighlighter>
        </div>

        <h3 className="titleType_2">Success Response Example</h3>
        <div className="ex_code">
          <SyntaxHighlighter
                language="json"
                style={a11yDark}
                showLineNumbers
              >
                {`{JobID: "0e1a5745-5b92-485d-a040-718251893bd8", State: "Success", Status: "AllDone", PoseEstimation: {…}, URL:
"https://hiveclouddev-golf-data-ap-northeast-2-3282…154e60d162c0f3d1b21716e6ca3000314b444b7f1008502ac"}JobID:
"0e1a5745-5b92-485d-a040-718251893bd8"PoseEstimation: list_of_joints: (192) [Array(1), Array(1), Array(1), Array(1),
Array(1), Array(3), Array(3), Array(4), Array(4), Array(3), Array(2), Array(4), Array(3), Array(3), Array(4), Array(3),
Array(2), Array(2), Array(4), Array(2), Array(2), Array(4), Array(3), Array(5), Array(3), Array(4), Array(3), Array(3),
Array(3), Array(2), Array(3), Array(5), Array(2), Array(3), Array(3), Array(4), Array(5), Array(4), Array(4), Array(5),
Array(4), Array(3), Array(3), Array(3), Array(3), Array(3), Array(3), Array(3), Array(6), Array(4), Array(4), Array(4),
Array(3), Array(2), Array(2), Array(3), Array(3), Array(2), Array(2), Array(5), Array(4), Array(4), Array(5), Array(3),
Array(2), Array(2), Array(2), Array(3), Array(3), Array(4), Array(2), Array(2), Array(2), Array(2), Array(2), Array(2),
Array(2), Array(2), Array(2), Array(3), Array(5), Array(6), Array(4), Array(5), Array(5), Array(4), Array(6), Array(5),
Array(4), Array(5), Array(4), Array(4), Array(3), Array(3), Array(3), Array(3), Array(1), Array(1), Array(1), Array(1),
…][0 … 99][100 … 191]length: 192__proto__: Array(0)__proto__: ObjectState: "Success"Status: "AllDone"URL:
"https://hiveclouddev-golf-data-ap-northeast-2-32822.s3.amazonaws.com/input/PoseEstimation/Full_Back/2021/7/21/0e1a5745-5b92-485d-a040-718251893bd8.mp4`}
              </SyntaxHighlighter>
        </div>
        <h3 className="titleType_2">Result Image Example</h3>
        <div className="txt_c mb_50">
          <img src="img/img_gpose_result_p.png" alt="" />
        </div>

        <h2 className="titleType_1">영상 분석 API (End2End API)</h2>
        <p className="txtType_1">
          HTTP Protocol 로 3MB 이하의 파일 업로드, 영상분석을 한번에 처리하여
          JSON 형태로 반환하는 API입니다. 영상 분석이 성공적으로 완료되면 골프
          스윙 준비 자세 프레임과 해당 프레임에서 가장 큰 사람의 관절 좌표가
          JSON 객체로 반환합니다.
        </p>

        <div className="listType_2">
          <ul>
            <li>URL : /sigmeta/pose/v1/e2e </li>
            <li>Method : POST</li>
            <li>Request</li>
          </ul>
        </div>

        <h3 className="titleType_2">Header</h3>
        <div className="tableType_2">
          <table>
            <colgroup>
              <col style={{ width: "137px" }} />
              <col style={{ width: "96px" }} />
              <col style={{ width: "492px" }} />
              <col style={{ width: "154px" }} />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Type</th>
                <th scope="col">Description</th>
                <th scope="col">Required</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Content-type</td>
                <td>String</td>
                <td>application/json</td>
                <td>Required</td>
              </tr>
              <tr>
                <td>App Key</td>
                <td>String</td>
                <td>
                  SK Open API의 App Key <br />
                  My Project > Key > Project Key (App Key)
                </td>
                <td>
                  Required <br />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <h3 className="titleType_2">Parameter</h3>
        <div className="tableType_2">
          <table>
            <colgroup>
              <col style={{ width: "137px" }} />
              <col style={{ width: "96px" }} />
              <col style={{ width: "206px" }} />
              <col style={{ width: "440px" }} />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">Key</th>
                <th scope="col">Value</th>
                <th scope="col">Type</th>
                <th scope="col">Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Type</td>
                <td>PoseEstimation</td>
                <td>String</td>
                <td>'PoseEstimation'</td>
              </tr>
              <tr>
                <td>File</td>
                <td>.mp4</td>
                <td>Multibyte form</td>
                <td>
                  동영상 파일 <br />
                  지원 Codec : H.264, MPEG<br />
                </td>
              </tr>
              {/* <tr>
                <td>Swing</td>
                <td>Full_Front</td>
                <td>String</td>
                <td>'Full_Front'</td>
              </tr> */}
              <tr>
                        <td>Error</td>
                        <td></td>
                        <td>String</td>
                        <td>Status Fail 시 Error 내용</td>
              </tr>
            </tbody>
          </table>
        </div>
        <h3 className="titleType_2">Request Body Example</h3>
        <div className="ex_code">
           <SyntaxHighlighter
                language="json"
                style={a11yDark}
                showLineNumbers
              >
                {`POST https://apis.openapi.sk.com/sigmeta/golf/v1/e2e
headers: {
  "content-type": "application/json",
  "appKey": appKey
  },
body: json {
  "Type": "PoseEstimation",
  “File”: file
  }`}
              </SyntaxHighlighter>
        </div>

        <h3 className="titleType_2">Success Response Example</h3>
        <div className="ex_code">
          <SyntaxHighlighter
                language="json"
                style={a11yDark}
                showLineNumbers
              >
                {`"State": "Success", "Result": “list_of_joints:” (192) [Array(1), Array(1), Array(1), Array(1), Array(1), Array(3),
Array(3), Array(4), Array(4), Array(3), Array(2), Array(4), Array(3), Array(3), Array(4), Array(3), Array(2), Array(2),
Array(4), Array(2), Array(2), Array(4), Array(3), Array(5), Array(3), Array(4), Array(3), Array(3), Array(3), Array(2),
Array(3), Array(5), Array(2), Array(3), Array(3), Array(4), Array(5), Array(4), Array(4), Array(5), Array(4), Array(3),
Array(3), Array(3), Array(3), Array(3), Array(3), Array(3), Array(6), Array(4), Array(4), Array(4), Array(3), Array(2),
Array(2), Array(3), Array(3), Array(2), Array(2), Array(5), Array(4), Array(4), Array(5), Array(3), Array(2), Array(2),
Array(2), Array(3), Array(3), Array(4), Array(2), Array(2), Array(2), Array(2), Array(2), Array(2), Array(2), Array(2),
Array(2), Array(3), Array(5), Array(6), Array(4), Array(5), Array(5), Array(4), Array(6), Array(5), Array(4), Array(5),
Array(4), Array(4), Array(3), Array(3), Array(3), Array(3), Array(1), Array(1), Array(1), Array(1), …][0 … 99][100 …
191]`}
              </SyntaxHighlighter>
        </div>
      </div>
    </section>
  );
};

export default PoseDocument;