import React from "react";
import ReactDOM from "react-dom";
import Router from "./Router";
import { BrowserRouter } from "react-router-dom";
import "./common/css/reset.css";
import "./common/css/common.css";
const Root = () => (
  <BrowserRouter>
    {/* <body className="gray_bg"> */}
    <div id="wrap">
      <Router />
    </div>
    {/* </body> */}
  </BrowserRouter>
);

export default Root;
