import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { ReactLocation } from 'react-location';

const Header = (props) => {
const history = useHistory();
const location = useLocation();

function handleClick() {
  history.go(-1);
  //event.preventDefault()
  //console.log(history.length);
};


  return (
    <div className="header sub_header">
      <div className="header_inner">
      {location.pathname !== '/' ? 
        <div className="logo">
        <Link to="/">
          <img src="img/logo_visionapi_top_p.svg" alt="SK Vision API" />
        </Link>
        </div>
        : 
        <div className="logoMain">
        <Link to="/">
          <img src="img/logo_visionapi_top_p.svg" alt="SK Vision API" />
        </Link>
        </div>
      }
        {(() => {
        switch (location.pathname) {
          case "/golfDocument":   return <h1 className="mb_top_title">API Document</h1>;
          case "/poseDocument":   return <h1 className="mb_top_title">API Document</h1>;
          case "/golfImageDocument":   return <h1 className="mb_top_title">API Document</h1>;
          case "/poseImageDocument":   return <h1 className="mb_top_title">API Document</h1>;
          case "/lprImageDocument":   return <h1 className="mb_top_title">API Document</h1>;
          case "/errorCode":   return <h1 className="mb_top_title">API Document</h1>;
          case "/golfSwingVideo":   return <h1 className="mb_top_title">API Demo</h1>;
          case "/poseEstimationVideo":   return <h1 className="mb_top_title">API Demo</h1>;
          case "/golfSwingImage":   return <h1 className="mb_top_title">API Demo</h1>;
          case "/poseEstimationImage":   return <h1 className="mb_top_title">API Demo</h1>;
          case "/lprImage":   return <h1 className="mb_top_title">API Demo</h1>;
          case "/mecCloud":   return <h1 className="mb_top_title">API Demo</h1>;
          default:      return <h1 className="mb_top_title"></h1>;
        }
        })()}
        
        {location.pathname !== '/' ? <button onClick={handleClick} className="page_back">뒤로</button> : null}
        
        <div className="menu_open">
          <button type="button" className="btn_menu_open">
            <span>메뉴열기</span>
          </button>
        </div>
        <div className="gnb">
          <div className="mobile_menu">
            <div className="gnb_logo">
            <a href="/">
            <img src="img/logo_visionapi_top_p.svg" alt="SK Vision API" />
          </a>
            </div>
            <button type="button" className="btn_gnb_close">
              메뉴닫기
            </button>
          </div>
          <ul>
            <li>
              <Link to="/golfDocument" id="close1">API Document</Link>
            </li>
            <li>
              <Link to="/golfSwingVideo" id="close2">API Demo</Link>
            </li>
            <li className="icon">
              <a href="#" onClick={() => window.open('https://openapi.sk.com/myproject/main', '_blank')}>API Key 발급</a>
            </li>
            <li className="icon">
              <a href="#" onClick={() => window.open('https://openapi.sk.com/support/cs/indexView?svcSeq=46', '_blank')}>문의하기</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default Header;

