import React, { useCallback, useEffect, useRef, useState } from "react";
import RequestApi from "../../RequestApi";
import { getInfo } from "react-mediainfo";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import DemoSideMenu from "../../common/sideMenu/DemoSideMenu";
import Loading from '../../loading';
import { Link } from "react-router-dom";
import ResponseInfo, { statusCodes } from "../../common/response/ResponseInfo";
const PoseEstimationVideoComponent = (match) => {
  const [state, setState] = useState(undefined);
  const [slide, setSlide] = useState(true);
  const [getResultData, setResultData] = useState(undefined);
  const body = document.querySelector("body");
  const [statusCode, setStatusCode] = useState(statusCodes);
  const [requestData, setRequestData] = useState({
    header: {
      appKey: "",
      "Content-Type": "application/json",
    },
    body: { file: undefined },
  });
  const [metaData, setMataData] = useState(undefined);
  const [httpResult, setHttpResult] = useState(undefined);
  const [canvasWidth, setCanvasWidth] = useState(null);
  const [loading, setLoading] = useState(undefined);
  const inputFileTag = useRef(null);
  const inputTextTag = useRef(null);
  const canvasTag = useRef(null);

  let video = undefined;
  let ctx = undefined;
  let myReq = undefined;
  let initFlag = true;
  let poseEstimationJoint = undefined;
  let requestId = undefined;
  let frameRate = 0;
  const path = match.match.path;
  useEffect(() => {
    if(document.querySelector(".lnb").style.display === 'block'){
      body.style.overflow = "hidden";
    }else{
      body.style.overflow = "";
    }
    a11yDark["hljs"].height = "100%";
  });
  let guidePoint = undefined;
  async function analzer() {
    if(document.getElementById("appKey").value === ''){
      alert('appKey를 입력해 주십시오.');
      return false;
    }else if(document.getElementById("file_name").value === ''){
      alert('파일을 업로드해 주십시오.');
      return false;
    }else{
      setState(undefined);
      setHttpResult(undefined);
      setLoading(true);
    const file = inputFileTag.current.files[0];
    frameRate = await getInfo(file).then((media) => {
      //파일 frame 수
      return media.media.track[0].FrameRate;
    });
    const apiKey = inputTextTag.current.value;
    const poseEstimation = await RequestApi(
      "PoseEstimation",
      "CreateAnalyzer",
      "Full_Front", //pose에서는 추 후 삭제
      apiKey, //appKey
      file,
      "/pose/v1/create_analyzer",
      "/pose/v1/get_result/"
    );
    const { getResult, result, createAnalyzer } = poseEstimation;
    setHttpResult(poseEstimation);
    setState(result.state);
    if(result.state == 'Fail'){
      setStatusCode(createAnalyzer.response.status)
    }
    if (result.type === "createAnalyzer") {
      setMataData(JSON.stringify(createAnalyzer, null, "  "));
    } else {
      setMataData(JSON.stringify(getResult.PoseEstimation, null, "  "));
    }
    setRequestData({
      baseUrl: process.env.REACT_APP_API_URL,
      header: {
        appKey: apiKey,
        "Content-Type": "application/json",
      },
      body: {
        file: file,
        Type: "PoseEstimation",
        Action: "getResult",
      },
    });
    setResultData({
      baseUrl: process.env.REACT_APP_API_URL+"/pose/v1/get_result/"+createAnalyzer.JobID,
      header: {
        appKey: apiKey
      },      
      method:"GET" 
    });
    if (getResult.State === "Success") {
      poseEstimationJoint = getResult.PoseEstimation.list_of_joints;
      // guidePoint = getResult.Guideline;
      const canvas = canvasTag.current;
      setCanvasWidth(canvasTag.current.width)
      ctx = canvas.getContext("2d");
      video = document.createElement("video");
      video.src = getResult.URL;
      video.muted = true;
      video.autoplay = true;
      video.play();
      video.loop = true;
      video.addEventListener("canplaythrough", render);
    }
  }
  setLoading(false);
}

  function render() {
    if (initFlag) {
      canvasTag.current.height = video.videoHeight;
      canvasTag.current.width = video.videoWidth;
      initFlag = false;
    }
    ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
    if (video.currentTime !== video.duration) {
      const currentFrame = Math.floor(video.currentTime.toFixed(5) * frameRate); //현재 프레임
      ctx.fillStyle = "red";
      poseEstimationJoint[currentFrame].forEach((jointList) => {
        jointList.forEach((joint) => {
          ctx.fillRect(joint[0], joint[1], 5, 5);
        });
          drawGuideline(
            jointList[2],
            jointList[3],
            "blue",
            "5"
          );
          drawGuideline(
            jointList[2],
            jointList[5],
            "blue",
            "5"
          );
          drawGuideline(
            jointList[2],
            jointList[9],
            "blue",
            "5"
          );
          drawGuideline(
            jointList[3],
            jointList[4],
            "blue",
            "5"
          );
          drawGuideline(
            jointList[5],
            jointList[6],
            "blue",
            "5"
          );
          drawGuideline(
            jointList[5],
            jointList[12],
            "blue",
            "5"
          );
          drawGuideline(
            jointList[6],
            jointList[7],
            "blue",
            "5"
          );
          drawGuideline(
            jointList[9],
            jointList[10],
            "blue",
            "5"
          );
          drawGuideline(
            jointList[9],
            jointList[12],
            "blue",
            "5"
          );
          drawGuideline(
            jointList[10],
            jointList[11],
            "blue",
            "5"
          );
          drawGuideline(
            jointList[11],
            jointList[22],
            "blue",
            "5"
          );
          drawGuideline(
            jointList[12],
            jointList[13],
            "blue",
            "5"
          );
          drawGuideline(
            jointList[13],
            jointList[14],
            "blue",
            "5"
          );
          drawGuideline(
            jointList[14],
            jointList[19],
            "blue",
            "5"
          );
      });
    }
    requestAnimationFrame(render);
  }
  function stop() {
    poseEstimationJoint = undefined;
    window.cancelAnimationFrame(requestAnimationFrame(render));
}
  function radioClear(value){
    // document.getElementById('imgType_1').checked = false
    // document.getElementById('imgType_2').checked = false
    //setState(undefined);
    //setHttpResult(undefined);
    if(value === undefined){
      //document.getElementById("file_name").value = "";
    }else{
      document.getElementById("file_name").value = value.name;
    }
    stop();
  }
  function drawGuideline(startPoint, endPoint, color, lineWidth) {
    // 곡선 , 원 , 포인트를 그리는 함수
    if(startPoint[0] > 0 && startPoint[1] > 0 && endPoint[0] > 0 && endPoint[1] > 0){
      ctx.fillRect(startPoint[0], startPoint[1], 10, 10);
      ctx.fillRect(endPoint[0], endPoint[1], 10, 10);
      ctx.strokeStyle = color;
      ctx.lineWidth = lineWidth;
      ctx.beginPath();
      ctx.moveTo(startPoint[0], startPoint[1]);
      ctx.lineTo(endPoint[0], endPoint[1]);
      ctx.closePath();
      ctx.stroke();
    }
  }
  return (
    <section className="container sub_container">
      <button
        className="btn_lnb_open"
        onClick={() => {
          if (slide) {
            document.querySelector(".lnb").style.display = "block";
            document.querySelector(".btn_lnb_open").classList.add("on")
            setSlide(false);
            body.style.overflow = "hidden";
          } else {
            document.querySelector(".lnb").style.display = "none";
            document.querySelector(".btn_lnb_open").classList.remove("on")
            setSlide(true);
            body.style.overflow = "";
          }
        }}
      >Pose Estimation</button>
      <DemoSideMenu path={path}></DemoSideMenu>
      <div className="subContent">
        <div className="sub_top_title">
          <div className="mb_24">
            <h1>Pose Estimation</h1>
            <div className="right_btn">
              <Link to="/poseDocument" className="btn">
                API Document
              </Link>
            </div>
          </div>

          <p className="txtType_1">
            영상을 분석해 사람의 머리, 눈, 코, 손목, 무릎 등 25개의 관절 포인트를 추출하는 기능을 제공합니다. 
          </p>
          <div className="tooltip">
            최대 용량 10MB / 해상도 640 ~ 1024 pixel인 영상만 분석이 가능합니다. 
            회전된 영상, 정면이 아닌 영상은 자세가 제대로 추출되지 않을 수 있습니다.
            mp4, mov 확장자 이외의 영상은 결과가 제대로 추출되지 않을 수 있습니다.
          </div>
        </div>

        <h2 className="titleType_1">요청</h2>
        <div className="listType_4">
          <ul>
            <li>
              <label htmlFor="appKey" className="title">
                appKey
              </label>
              <input type="text" id="appKey" ref={inputTextTag}  />
            </li>
            <li>
              <span className="title">File</span>
              <div className="right_btn">
                <input type="text" id="file_name" disabled/>
                <input type="file" id="file_upload" accept=".mp4, .mkv, .gif, .avi " style={{ display: "none" }} ref={inputFileTag} onChange={(e)=> radioClear(e.target.files[0])}/>
                <label htmlFor="file_upload" className="btn">
                  Upload
                </label>
              </div>
            </li>
          </ul>
        </div>
        <div className="txt_r">
          <input
            type="submit"
            value="전송"
            className="basic-btn01 btn-red-bg"
            onClick={analzer}
          />
        </div>
      </div>
      {state === undefined ? (
        <></>
      ) : state === "Success" ? (
        <div className="sub_box">
          <div className="title_area">
            <h2>응답</h2>
          </div>
          <div className="sub_box_content">
            <h3 className="titleType_2">분석 결과</h3>
            <div className="img_list">
              <ul>
                <li>
                  <div className="img_area">
                    {/* <img src="../img/img_golf_video_p.jpg" alt="" /> */}
                    <canvas
                      ref={canvasTag}
                      // style={{ width: "640px", height: "400px" }}
                      style={{ width: canvasWidth }}
                      className="canvas"
                    ></canvas>
                  </div>
                  <div className="txtArea">분석 영상</div>
                </li>
              </ul>
            </div>
            <div className="top_right_btn_area">
              <h4 className="titleType_2">Meta in json</h4>
            </div>
            {/* <div className="top_right_btn_area">
              <h4 className="titleType_2">Response</h4>
              <div
                className={`state ` + statusCodes[httpResult.getResult.Status]}
              >
                <span className="number">500</span>
                <div className="txt">{httpResult.getResult.Status}</div>
              </div>
            </div> */}
            <div className="codeArea">
              <textarea
                style={{
                  height: "100%",
                  backgroundColor: "black",
                  color: "white",
                }}
                value={metaData}
              ></textarea>
              {/* <HighlightComponent metaData={metaData}></HighlightComponent> */}
              {/* <Highlight className="json">{`${metaData}`}</Highlight> */}
              {/* <textarea value={metaData}></textarea> */}
              {/* <SyntaxHighlighter
                language="json"
                style={a11yDark}
                showLineNumbers
              >
                {`${metaData}`}
              </SyntaxHighlighter> */}
            </div>
          </div>
        </div>
      ) : (
        <div className="sub_box">
          <div className="title_area">
            <h2>응답</h2>
          </div>
          <div className="sub_box_content">
            <h3 className="titleType_2">분석 결과</h3>

            <div className="analysis_result">
              <div className="no_result">
                <p className="error_txt">
                  동영상이 분석되지 않았습니다. <br />
                  다시 시도해 주십시오.
                  <span>{`(Response Code :`+ statusCode +`)`} </span>
                </p>
              </div>
            </div>

            <h3 className="titleType_4 big">Meta in json</h3>
            {/* <div className="top_right_btn_area">
              <h4 className="titleType_2">Response</h4>
              <div
                className={`state ` + statusCodes[httpResult.getResult.Status]}
              >
                <span className="number">500</span>
                <div className="txt">{httpResult.getResult.Status}</div>
              </div>
            </div> */}
            <div className="codeArea">
              <textarea
                style={{
                  height: "100%",
                  backgroundColor: "black",
                  color: "white",
                }}
                value={metaData}
              ></textarea>
              {/* <Highlight className="json">{`${metaData}`}</Highlight> */}
              {/* <SyntaxHighlighter
                language="json"
                style={a11yDark}
                showLineNumbers
                wrapLongLines
              >
                {`${metaData}`}
              </SyntaxHighlighter> */}
            </div>
          </div>
        </div>
      )}
      <ResponseInfo
        httpResult={httpResult}
        requestData={requestData}
        getResultData={getResultData}
        type="pose"
      ></ResponseInfo>
      <div className="loading">{loading? <Loading></Loading> : ""}</div>
    </section>
  );
};

export default PoseEstimationVideoComponent;
