import { Link } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import DocumentSideMenu from "../../common/sideMenu/DocumentSideMenu";
import DemoSideMenu from "../../common/sideMenu/DemoSideMenu";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import SyntaxHighlighter from "react-syntax-highlighter";
const GolfDocument = (match) => {
  const path = match.match.path;
  const [state, setState] = useState(undefined);
  const [slide, setSlide] = useState(true);
  const body = document.querySelector("body");
  useEffect(() => {
    if(document.querySelector(".lnb").style.display === 'block'){
      body.style.overflow = "hidden";
    }else{
      body.style.overflow = "";
    }
  });
  return (
    <section className="container sub_container">
      <button
        className="btn_lnb_open"
        onClick={() => {
          if (slide) {
            document.querySelector(".lnb").style.display = "block";
            document.querySelector(".btn_lnb_open").classList.add("on")
            setSlide(false);
            body.style.overflow = "hidden";
          } else {
            document.querySelector(".lnb").style.display = "none";
            document.querySelector(".btn_lnb_open").classList.remove("on")
            setSlide(true);
            body.style.overflow = "";
          }
        }}
      >Golf Swing Analyzer</button>
      <DocumentSideMenu
        path={path}
        routeLink="/GolfDocument"
      ></DocumentSideMenu>
      <div className="subContent">
        <div className="sub_top_title">
          <h1>Golf Swing Analyzer</h1>
          <div className="right_btn">
            <Link to="/golfSwingVideo" className="btn">
              API Demo
            </Link>
          </div>
        </div>
        <p className="txtType_1 mb_50">
          SKT Pose API 를 이용하여 실내 스크린 골프 존 내
          스윙 영상을 분석해서 골프 스윙 준비 자세를 추출하고, 준비 자세가
          알맞도록 사람의 머리, 관절, 골프 공 등을 추출해 Guideline 을
          제공합니다.
        </p>

        <h2 className="titleType_1">제공기능</h2>
        <div className="listType_1">
          <ul>
            <li>
              <div className="title">
                <span>create_analyzer</span>
              </div>
              <div className="txt">
                <div className="inner">
                  골프 스윙 영상 내, 준비 자세의 Frame을 추출하기 위해
                  영상을 업로드 할 수 있는 URL과 Job ID 반환하는 API
                </div>
              </div>
            </li>
            <li>
              <div className="title">
                <span>start_analyzer</span>
              </div>
              <div className="txt">
                <div className="inner">
                  영상 파일을 올리면 준비자세, 관절 포인트, Guideline 을
                  분석하는 기능을 실행하고 작업물의 처리상태를 반환하는 API
                </div>
              </div>
            </li>
            <li>
              <div className="title">
                <span>get_result</span>
              </div>
              <div className="txt">
                <div className="inner">
                  영상분석하기 (start_analyzer) API에서 분석된 결과물을 json
                  형태로 반환하는 API
                </div>
              </div>
            </li>
            <li>
              <div className="title">
                <span>e2e</span>
              </div>
              <div className="txt">
                <div className="inner">
                  HTTP Protocol로 3MB 이하의 파일 업로드, 영상분석을 한번에
                  처리하여 JSON 형태로 반환하는 API
                </div>
              </div>
            </li>
          </ul>
        </div>

        <h2 className="titleType_1">주의사항</h2>
        <div className="listType_2 mb_40">
          <ul>
            <li>
              최대 10MB 용량의 영상 분석이 가능하며, 초기 4초의 한번 스윙에 대해
              분석이 가능합니다.
            </li>
            <li>
              스크린 골프장 영상에 적합하게 설계되어, 야외 영상 및 한 명 기준의
              골프 스윙이 아닌 영상, 회전된 영상 등은 준비 자세가 제대로
              추출되지 않을 수 있습니다.
            </li>
          </ul>
        </div>

        <h2 className="titleType_1">개발가이드</h2>
        <p className="txtType_1">
          SDK 를 지원하지 않으며 HTTP API 방식으로
          구현할 수 있습니다.
        </p>
        <div className="listType_2 mb_40">
          <ul>
            <li>
              요청 시 HTTP Connection Timeout은 5초, read TimeOut 은 20초로
              설정합니다.
            </li>
            <li>
              과도하게 많은 요청을 보내어 초당 요청 한도를 초과하는 경우 오류
              응답을 받을 수 있습니다.
            </li>
            <li>
              Create_analyzer API 요청 시 Response 이후 다음 API 호출을 위한
              최대 시간은 15분입니다.
            </li>
            <li>
              영상 해상도는 최소 640 pixel, 최대 1024 pixel에 최적화되어 이 외 해상도에는 결과가 제대로 추출되지 않을 수 있습니다. 
            </li>
            <li>
              mp4, mov 확장자 이외의 영상은 결과가 제대로 추출되지 않을 수 있습니다.
            </li>
          </ul>
        </div>

        <h2 className="titleType_1">영상 업로드 (create_analyzer API)</h2>
        <p className="txtType_1">
          업로드 요청 시 영상을 업로드 할 수 있는 URL을
          반환합니다.
        </p>
        <div className="listType_2">
          <ul>
            <li>
              URL : /golf/v1/create_analyzer 
            </li>
            <li>Method : POST</li>
            <li>Request</li>
          </ul>
        </div>

        <h3 className="titleType_2">Header</h3>
        <div className="tableType_2">
          <table>
            <colgroup>
              <col style={{ width: "137px" }} />
              <col style={{ width: "96px" }} />
              <col style={{ width: "490px" }} />
              <col style={{ width: "154px" }} />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Type</th>
                <th scope="col">Description</th>
                <th scope="col">Required</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Content-type</td>
                <td>String</td>
                <td>application/json</td>
                <td>Required</td>
              </tr>
              <tr>
                <td>App Key</td>
                <td>String</td>
                <td>
                  SK Open API의 App Key <br />
                  My Project > Key > Project Key (App Key)
                </td>
                <td>
                  Required <br />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <h3 className="titleType_2">Parameter</h3>
        <div className="tableType_2">
          <table>
            <colgroup>
              <col style={{ width: "137px" }} />
              <col style={{ width: "154px" }} />
              <col style={{ width: "95px" }} />
              <col style={{ width: "338px" }} />
              <col style={{ width: "154px" }} />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">Key</th>
                <th scope="col">Value</th>
                <th scope="col">Type</th>
                <th scope="col">Description</th>
                <th scope="col">Required</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Type</td>
                <td>Guideline</td>
                <td>String</td>
                <td>'Guideline'</td>
                <td>Required</td>
              </tr>
              <tr>
                <td>Action</td>
                <td>CreateAnalyzer</td>
                <td>String</td>
                <td>“CreateAnalyzer”</td>
                <td>Required</td>
              </tr>
              <tr>
                <td>Swing</td>
                <td>Full_Front</td>
                <td>String</td>
                <td>'Full_Front' or 'Full_Back'</td>
                <td>Required</td>
              </tr>
            </tbody>
          </table>
        </div>

        <h3 className="titleType_2">Request Body Example</h3>
        <div className="ex_code">
          <SyntaxHighlighter
                language="json"
                style={a11yDark}
                showLineNumbers
              >
                {`POST https://apis.openapi.sk.com/sigmeta/golf/v1/create_analyzer
headers: {
  "content-type": "application/json",
  "appKey": appKey
  },
body: json {
  "Type": "Guideline",
  "Action": "CreateAnalyzer",
  "Swing": "Full_Front"
  }`}
              </SyntaxHighlighter>
        </div>

        <h4 className="titleType_3">Response</h4>
        <div className="tableType_2">
          <table>
            <colgroup>
              <col style={{ width: "137px" }} />
              <col style={{ width: "96px" }} />
              <col style={{ width: "647px" }} />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Type</th>
                <th scope="col">Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>JobID</td>
                <td>Int</td>
                <td>부여된 Job ID</td>
              </tr>
              <tr>
                <td>State</td>
                <td>String</td>
                <td>“Success” or “Fail”</td>
              </tr>
              <tr>
                <td>Status</td>
                <td>String</td>
                <td>“Create”, “NotCreated”, “Analyzing”, “AllDone” or “Failed”</td>
              </tr>
              <tr>
                <td>URL</td>
                <td>String</td>
                <td>파일 올릴 수 있는 S3 임시 URL</td>
              </tr>
              <tr>
                <td>Form</td>
                <td>String</td>
                <td>
                  파일 업로드를 위한 S3 Bucket 정보 <br />
                  [key, policy, x-amz-algorithm, x-amz-credential, x-amz-date,
                  x-amz-security-token, x-amz-signature]
                </td>
              </tr>
              <tr>
                        <td>Error</td>
                        <td>String</td>
                        <td>Status Fail 시 Error 내용</td>
              </tr>
            </tbody>
          </table>
        </div>

        <h3 className="titleType_2">Success Response Example</h3>
        <div className="ex_code">
          <SyntaxHighlighter
                language="json"
                style={a11yDark}
                showLineNumbers
              >
                {`{JobID: "99708869-926b-4be0-ad5f-f9e02bfb4e92", State: "Success", Status: "Created", URL:
"https://hivevisionprod-golf-data-ap-northeast-2-40524.s3.amazonaws.com/", Form: {…}}
Form:
key: "input/Guideline/Full_Front/2021/7/8/2b4824a5-2110-43b4-827d-c55538e796c3.mp4"
policy: "eyJleHBpcmF0aW9uIjogIjIwMjEt…=”
x-amz-algorithm: "AWS4-HMAC-SHA256"
x-amz-credential: "ASIAYL6RXC3H2QVVIO6T/20210708/ap-northeast-2/s3/aws4_request"
x-amz-date: "20210708T062608Z"
x-amz-security-token: "IQoJb3JpZ2luX2VjEIb///="
x-amz-signature: "36012db607d9a390b3940d8162999826036b503eb65aa78a69a918c46e391f0c`}
              </SyntaxHighlighter>
        </div>

        <h2 className="titleType_1">영상 분석하기 (start_analyzer API)</h2>
        <p className="txtType_1">
          입력된 영상을 다중 이미지화하여 골프 스윙 준비 자세 프레임를 추출하고
          해당 자세에서 사람의 코, 눈 귀, 어깨, 팔꿈치, 손목, 골반, 무릎, 발목,
          골프 클럽, 골프 공 등 28개의 키 포인트를 추출하여 골프스윙 준비 자세
          가이드를 제공합니다.
        </p>
        <div className="listType_2">
          <ul>
            <li>URL : {'{URL}'}</li>
            <li>Method : POST</li>
            <li>Request</li>
          </ul>
        </div>
        <div className="tableType_2">
          <table>
            <colgroup>
              <col style={{ width: "137px" }} />
              <col style={{ width: "96px" }} />
              <col style={{ width: "647px" }} />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Type</th>
                <th scope="col">Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Form</td>
                <td>String</td>
                <td>
                  파일 업로드를 위한 S3 Bucket 정보 (CreateAnalyzer 요청 시
                  받음) <br />
                  [key, policy, x-amz-algorithm, x-amz-credential, x-amz-date,{" "}
                  <br />
                  x-amz-security-token, x-amz-signature]
                </td>
              </tr>
              <tr>
                <td>File</td>
                <td>File</td>
                <td>
                  동영상 파일 <br />
                  지원 Codec : H.264, MPEG <br />
                  {/* 지원 확장자 : mp4, mov, avi, flv, mkv, ts, gif, ogv, m4v, vob, wmv <br /> */}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <h2 className="titleType_1">분석 결과 확인하기 (get_result API)</h2>
        <div className="txtType_1">
          영상분석하기 (start_analyzer) API를 통해 요청한 작업물의 결과값을 JSON
          형태로 반환하는 API입니다. 영상 업로드 요청 시 전달받은 JobID를 URL에
          담고 GET으로 영상 포즈 분석 결과를 확인합니다. 영상 분석이 성공적으로
          완료되면 골프 스윙 준비 자세 프레임과 해당 프레임에서 가장 큰 사람의
          관절 좌표가 JSON 객체로 반환합니다.
        </div>
        <div className="listType_2">
          <ul>
            <li>
              URL : /golf/v1/get_result/:JobID 
            </li>
            <li>Method : GET</li>
            <li>Request</li>
          </ul>
        </div>

        <h3 className="titleType_2">Header</h3>

        <div className="tableType_2">
          <table>
            <colgroup>
              <col style={{ width: "137px" }} />
              <col style={{ width: "96px" }} />
              <col style={{ width: "206px" }} />
              <col style={{ width: "440px" }} />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Type</th>
                <th scope="col">Description</th>
                <th scope="col">Required</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>appKey</td>
                <td>String</td>
                <td>SK Open API의 App Key</td>
                <td>Required (SK Open API)</td>
              </tr>
            </tbody>
          </table>
        </div>

        <h3 className="titleType_3">Request</h3>
        <div className="tableType_2">
          <table>
            <colgroup>
              <col style={{ width: "137px" }} />
              <col style={{ width: "96px" }} />
              <col style={{ width: "647px" }} />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Type</th>
                <th scope="col">Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>JobID</td>
                <td>String</td>
                <td>해당 분석의 Job ID</td>
              </tr>
              <tr>
                <td>State</td>
                <td>String</td>
                <td>“Success” or “Fail”</td>
              </tr>
              <tr>
                <td>Status</td>
                <td>String</td>
                <td>“Create”, “NotCreated”, “Analyzing”, “AllDone” or “Fail”</td>
              </tr>
              <tr>
                <td>address_frame_num</td>
                <td>Int</td>
                <td>추출된 스윙 준비 자세 Frame Number</td>
              </tr>
              <tr>
                <td>Joints</td>
                <td>Array[]</td>
                <td>
                  25개의 관절 좌표 <br />
                  >추출되지 않았을 경우 -1 로 반환 <br />
                  [nose, neck, right shoulder, right elbow, right wrist, left
                  shoulder, left elbow, left wrist, pelvis, right hip, right
                  knee, right ankle, left hip, left knee, left ankle, right eye,
                  left eye, right ear, left ear, left big toe, left small toe,
                  left heel, right big toe, right small toe, right heel]
                </td>
              </tr>
              <tr>
                <td>Guideline</td>
                <td>Array[]</td>
                <td>
                  Front 의 Guideline 생성을 위한 Joints 외 10개의 좌표 <br />
                  [body_line_left_bottom_point, body_line_left_top_point,
                  body_line_right_bottom_point, body_line_right_top_point,
                  head_center, head_circle_radius,
                  hip_ankle_line_leftbottom_point, hip_ankle_line_lefttop_point,
                  hip_ankle_line_rightbottom_point,
                  hip_ankle_line_righttop_point, shoulder_line_left_point,
                  shoulder_line_right_point]
                </td>
              </tr>
              <tr>
                <td>url</td>
                <td>String</td>
                <td>Meta가 저장되어 있는 URL</td>
              </tr>
              <tr>
                        <td>Error</td>
                        <td>String</td>
                        <td>Status Fail 시 Error 내용</td>
              </tr>
            </tbody>
          </table>
        </div>

        <h3 className="titleType_2">Request Body Example</h3>
        <div className="ex_code">
          <SyntaxHighlighter
                language="json"
                style={a11yDark}
                showLineNumbers
              >
                {`POST https://apis.openapi.sk.com/sigmeta/golf/v1/get_result/99708869-926b-4be0-ad5f-f9e02bfb4e92
headers: {
  "appKey": appKey
  }'`}
              </SyntaxHighlighter>
        </div>

        <h3 className="titleType_2">Success Response Example</h3>
        <div className="ex_code">
           <SyntaxHighlighter
                language="json"
                style={a11yDark}
                showLineNumbers
              >
                {`{JobID: "2b4824a5-2110-43b4-827d-c55538e796c3", State: "Success", Status: "AllDone", Guideline: {…}, URL:
"https://hivevisionprod-golf-data-ap-northeast-2-40524.…b5b4ded6ae8264be549b86afce33d3c778a5c27d2dfdd4ad8"}
Guideline:
address_frame_num: [38]
body_line_left_bottom_point: (2) [-14, 413]
body_line_left_top_point: (2) [-14, -27]
body_line_right_bottom_point: (2) [12, 413]
body_line_right_top_point: (2) [12, -27]
head_center: (2) [-1, -7]
head_circle_radius: [22]
hip_ankle_line_leftbottom_point: (2) [332, 396]
hip_ankle_line_lefttop_point: (2) [-1, -1]
hip_ankle_line_rightbottom_point: (2) [-1, -1]
hip_ankle_line_righttop_point: (2) [-1, -1]
joints: (25) [Array(3), Array(3), Array(3), Array(3), Array(3), Array(3), Array(3), Array(3), Array(3), Array(3),
Array(3), Array(3), Array(3), Array(3), Array(3), Array(3), Array(3), Array(3), Array(3), Array(3), Array(3), Array(3),
Array(3), Array(3), Array(3)]
shoulder_line_left_point: (2) [-14, 0]
shoulder_line_right_point: (2) [12, 0]`}
              </SyntaxHighlighter>
        </div>
        <h3 className="titleType_2">Result Image Example</h3>
        <div className="txt_c mb_50">
          <img src="../img/img_golf_result_p.jpg" alt="" />
        </div>

        <h2 className="titleType_1">영상 분석 API (End2End API)</h2>
        <p className="txtType_1">
          HTTP Protocol 로 3MB 이하의 파일 업로드, 영상분석을 한번에 처리하여
          JSON 형태로 반환하는 API입니다. 영상 분석이 성공적으로 완료되면 골프
          스윙 준비 자세 프레임과 해당 프레임에서 가장 큰 사람의 관절 좌표가
          JSON 객체로 반환합니다.
        </p>

        <div className="listType_2">
          <ul>
            <li>URL : /golf/v1/e2e (SK Open API)</li>
            <li>Method : POST</li>
            <li>Request</li>
          </ul>
        </div>

        <h3 className="titleType_2">Header</h3>
        <div className="tableType_2">
          <table>
            <colgroup>
              <col style={{ width: "137px" }} />
              <col style={{ width: "96px" }} />
              <col style={{ width: "492px" }} />
              <col style={{ width: "154px" }} />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Type</th>
                <th scope="col">Description</th>
                <th scope="col">Required</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Content-type</td>
                <td>String</td>
                <td>application/json</td>
                <td>Required</td>
              </tr>
              <tr>
                <td>App Key</td>
                <td>String</td>
                <td>
                  SK Open API의 App Key <br />
                  My Project > Key > Project Key (App Key)
                </td>
                <td>
                  Required 
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <h3 className="titleType_2">Parameter</h3>
        <div className="tableType_2">
          <table>
            <colgroup>
              <col style={{ width: "137px" }} />
              <col style={{ width: "96px" }} />
              <col style={{ width: "206px" }} />
              <col style={{ width: "440px" }} />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">Key</th>
                <th scope="col">Value</th>
                <th scope="col">Type</th>
                <th scope="col">Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Type</td>
                <td>Guideline</td>
                <td>String</td>
                <td>'Guideline'</td>
              </tr>
              <tr>
                <td>Swing</td>
                <td>Full_Front</td>
                <td>String</td>
                <td>'Full_Front' or 'Full_Back'</td>
              </tr>
              <tr>
                <td>File</td>
                <td>.mp4</td>
                <td>Multibyte form</td>
                <td>
                  동영상 파일 <br />
                  지원 Codec : H.264, MPEG <br />
                  {/* 지원 확장자 : mp4, mov, avi, flv, mkv, ts, gif, ogv, m4v, vob, wmv <br /> */}
                </td>
              </tr>
              <tr>
                        <td>Error</td>
                        <td></td>
                        <td>String</td>
                        <td>Status Fail 시 Error 내용</td>
              </tr>
            </tbody>
          </table>
        </div>
        <h3 className="titleType_2">Request Body Example</h3>
        <div className="ex_code">
          <SyntaxHighlighter
                language="json"
                style={a11yDark}
                showLineNumbers
              >
                {`POST https://apis.openapi.sk.com/sigmeta/golf/v1/e2e
headers: {
"content-type": "application/json",
"appKey": appKey
},
body: json {
"Type": "Guideline",
"Swing": "Full_Front"
“File”: file
}`}
              </SyntaxHighlighter>
        </div>

        <h3 className="titleType_2">Success Response Example</h3>
        <div className="ex_code">
          <SyntaxHighlighter
                language="json"
                style={a11yDark}
                showLineNumbers
              >
                {`"State": "Success", "Result": {"head_center": [-1, -7], "head_circle_radius": [22], "hip_ankle_line_lefttop_point": [-1,
-1], "hip_ankle_line_leftbottom_point": [332, 396], "hip_ankle_line_righttop_point": [-1, -1],
"hip_ankle_line_rightbottom_point": [-1, -1], "body_line_left_top_point": [-14, -27], "body_line_left_bottom_point":
[-14, 413], "body_line_right_top_point": [12, -27], "body_line_right_bottom_point": [12, 413],
"shoulder_line_left_point": [-14, 0], "shoulder_line_right_point": [12, 0], "address_frame_num": [38.0], "joints":
[[-1.0, -1.0, -1.0], [-1.0, -1.0, -1.0], [-1.0, -1.0, -1.0], [-1.0, -1.0, -1.0], [-1.0, -1.0, -1.0], [-1.0, -1.0, -1.0],
[-1.0, -1.0, -1.0], [-1.0, -1.0, -1.0], [-1.0, -1.0, -1.0], [-1.0, -1.0, -1.0], [-1.0, -1.0, -1.0], [332.0, 396.0, 0.1],
[-1.0, -1.0, -1.0], [-1.0, -1.0, -1.0], [-1.0, -1.0, -1.0], [-1.0, -1.0, -1.0], [-1.0, -1.0, -1.0], [-1.0, -1.0, -1.0],
[-1.0, -1.0, -1.0], [-1.0, -1.0, -1.0], [-1.0, -1.0, -1.0], [-1.0, -1.0, -1.0], [288.0, 424.0, 0.3], [288.0, 416.0,
0.2], [528.0, 168.0, 0.1]]}} }`}
              </SyntaxHighlighter>
        </div>
      </div>
    </section>
  );
};
export default GolfDocument;
