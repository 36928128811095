import { Link } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import DocumentSideMenu from "../../common/sideMenu/DocumentSideMenu";
import DemoSideMenu from "../../common/sideMenu/DemoSideMenu";
const ErrorCode = (match) => {
  const path = match.match.path;
  const [state, setState] = useState(undefined);
  const [slide, setSlide] = useState(true);
  const body = document.querySelector("body");
  useEffect(() => {
    if(document.querySelector(".lnb").style.display === 'block'){
      body.style.overflow = "hidden";
    }else{
      body.style.overflow = "";
    }
  });
  return (
    <section className="container sub_container">
      <button
        className="btn_lnb_open"
        onClick={() => {
          if (slide) {
            document.querySelector(".lnb").style.display = "block";
            document.querySelector(".btn_lnb_open").classList.add("on")
            setSlide(false);
            body.style.overflow = "hidden";
          } else {
            document.querySelector(".lnb").style.display = "none";
            document.querySelector(".btn_lnb_open").classList.remove("on")
            setSlide(true);
            body.style.overflow = "";
          }
        }}
      >Error Code</button>
      <DocumentSideMenu
        path={path}
        routeLink="/ErrorCode"
      ></DocumentSideMenu>
      <div className="subContent">
      <div className="sub_top_title">
            <h1>Error Code/Message</h1>
        </div>
        <h2 className="titleType_5">HTTP Error Code</h2>
        <div className="tableType_2">
            <table>
                <colgroup>
                <col style={{ width: "137px" }} />
                <col style={{ width: "239px" }} />
                <col style={{ width: "294px" }} />
                <col style={{ width: "209px" }} />
                </colgroup>
                <thead>
                    <tr>
                        <th scope="col">Error</th>
                        <th scope="col">Message</th>
                        <th scope="col">Error Status</th>
                        <th scope="col">API</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="green">
                        <td><span className="number">200</span></td>
                        <td className="c-green">Success</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr className="red">
                        <td><span className="number">204</span></td>
                        <td className="c-red">No Content</td>
                        <td>
                            File Missing <br/>
                            File Upload Success (multipart form)
                        </td>
                        <td>
                            start_analyzer <br/>
                            get_result
                        </td>
                    </tr>
                    <tr className="red">
                        <td><span className="number">400</span></td>
                        <td className="c-red">Bad Request</td>
                        <td>
                            key 파라미터 미입력 <br/>
                            'x-amz-security-token' 파라미터 오입력<br/>
                            이외의 파라미터 미입력/오입력<br/>
                            3MB 용량 초과 (unknown type of error)
                        </td>
                        <td>
                            start_analyzer <br/>
                            e2e
                        </td>
                    </tr>
                    <tr className="red">
                        <td rowSpan="2"><span className="number">403</span></td>
                        <td rowSpan="2" className="c-red">Forbidden</td>
                        
                    </tr>
                    <tr>
                        <td>
                            File Parameter Missing / 오입력 <br/>
                            ‘key' 파라미터 오입력<br/>
                            'x-amz-security-token' 파라미터 미입력<br/>
                            'policy' 파라미터 미입력/오입력<br/>
                            'x-amz-signature' 파라미터 미입력/오입력
                        </td>
                        <td>start_analyzer</td>
                    </tr>
                    <tr className="red">
                        <td><span className="number">413</span></td>
                        <td className="c-red">Request Entity Too Large</td>
                        <td>
                            3MB 용량 초과
                        </td>
                        <td>
                            e2e
                        </td>
                    </tr>
                    <tr className="red">
                        <td><span className="number">500</span></td>
                        <td className="c-red">Internal Server Error</td>
                        <td>
                            File Parameter Missing <br/>
                            영상 확장자 오류 <br/>
                            분석 제한 영상(4초 미만 영상 등-Guideline)
                        </td>
                        <td>
                            e2e
                        </td>
                    </tr>
                    <tr className="red">
                        <td><span className="number">503</span></td>
                        <td className="c-red">Service Unavailable</td>
                        <td>
                            영상 확장자 오류 <br/>
                            사용자 테스트 부하 <br/>
                            서버 내부 에러
                        </td>
                        <td>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <h2 className="titleType_5">Common API Error Message</h2>
        <div className="tableType_2">
            <table>
                <colgroup>
                <col style={{ width: "237px" }} />
                <col style={{ width: "354px" }} />
                <col style={{ width: "296px" }} />
                </colgroup>
                <thead>
                    <tr>
                        <th scope="col">Error Message</th>
                        <th scope="col">Error Case</th>
                        <th scope="col">API</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Not Defined Action</td>
                        <td>Action Value Error</td>
                        <td>start_analyzer</td>
                    </tr>
                    <tr>
                        <td>Fail in CreateAnalyze : {`{}`}</td>
                        <td>Type/Swing Value Error</td>
                        <td>start_analyzer</td>
                    </tr>
                    <tr>
                        <td>
                            fail in GetResult: no <br/>
                            previous history
                        </td>
                        <td>JobID 오류</td>
                        <td>start_analyzer</td>
                    </tr>
                    <tr>
                        <td>Internal Error</td>
                        <td>File/Swing/Type Parameter 미입력</td>
                        <td>get result</td>
                    </tr>
                    <tr>
                        <td>Internal Error</td>
                        <td>
                            file value 미입력 <br/>
                            확장자 오류
                        </td>
                        <td>e2e</td>
                    </tr>
                    <tr>
                        <td>Invalid API Key</td>
                        <td>
                            appKey 오류
                        </td>
                        <td>start_analyzer, e2e</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <h2 className="titleType_5">Golf Swing Analyzer</h2>
        <div className="tableType_2">
            <table>
                <colgroup>
                <col style={{ width: "237px" }} />
                <col style={{ width: "354px" }} />
                <col style={{ width: "296px" }} />
                </colgroup>
                <thead>
                    <tr>
                        <th scope="col">Error Message</th>
                        <th scope="col">Error Case</th>
                        <th scope="col">API</th>
                    </tr>
                </thead>
                <tbody>
                <tr>
                        <td>fail in CreateAnalyze : {`{}`}</td>
                        <td>이미지 확장자 오류</td>
                        <td>create analyzer(image)</td>
                    </tr>
                    <tr>
                        <td>get_guideline</td>
                        <td>
                            영상 확장자 오류 <br/>
                            keyframe 추출 fail 시 (4초 이후 스윙 영상) <br/>
                            file value 미입력
                        </td>
                        <td>get result (video)</td>
                    </tr>
                    <tr>
                        <td>estimate_pose</td>
                        <td>관절 추출 오류</td>
                        <td>get result</td>
                    </tr>
                    <tr>
                        <td>get_keyframe</td>
                        <td>keyframe 추출 오류</td>
                        <td>get result (video)</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <h2 className="titleType_5">Pose Estimation</h2>
        <div className="tableType_2">
            <table>
                <colgroup>
                <col style={{ width: "237px" }} />
                <col style={{ width: "354px" }} />
                <col style={{ width: "296px" }} />
                </colgroup>
                <thead>
                    <tr>
                        <th scope="col">Error Message</th>
                        <th scope="col">Error Case</th>
                        <th scope="col">API</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>none_of_frames/joints</td>
                        <td>
                            제한 요건 이상 영상 요청 (1000 frame 이상) <br/>
                            File value 미입력<br/>
                            관절 추출 Fail
                        </td>
                        <td>get result</td>
                    </tr>
                    <tr>
                        <td>[ERROR] :start one input</td>
                        <td>
                            관절 추출 Fail <br/>
                            확장자 오류
                        </td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
        
    </div>
</section>
  );
};
export default ErrorCode;
