import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Root from "./Root";

// window.onload = async () => {
//     const { default: VConsole } = await import('vconsole');
//     window._vconsole = new VConsole();
 
//     console.log('vConsole opened!!')
   ReactDOM.render(<Root />, document.getElementById("root"));
//}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
