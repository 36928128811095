import logo from "./logo.svg";
import React from "react";
import "./App.css";
import { Route, Switch } from "react-router-dom";
import Main from "./Main";
import GolfSwingVideoComponent from "./video/golfSwingVideo/GolfSwingVideoComponent";
import PoseEstimationVideoComponent from "./video/poseEstimationVideo/PoseEstimationVideoComponent";
import GolfSwingImageComponent from "./image/golfSwingImage/GolfSwingImageComponent";
import PoseEstimationImageComponent from "./image/PoseEstimationImage/PoseEstimationImageComponent";
import LprImageComponent from "./image/LprImage/LprImageComponent";
import GolfDocument from "./document/Video/GolfDocument";
import PoseDocument from "./document/Video/PoseDocument";
import GolfImageDocument from "./document/image/GolfImageDocument";
import PoseImageDocument from "./document/image/PoseImageDocument";
import LprImageDocument from "./document/image/LprImageDocument";
import ErrorCode from "./document/image/ErrorCode";
import MecCloud from "./video/mecCloud/MecCloudComponent";
import Header from "./common/Header";
import Footer from "./common/Footer";
import "./common/js/sb_common";

const Router = () => {
  return (
    <>

          <Route
            render={props => (  
              <>
                <Header />
                <Switch>
                  <Route exact path="/" component={Main} />
                  <Route path="/golfSwingVideo" component={GolfSwingVideoComponent}></Route>
                  <Route
                    path="/poseEstimationVideo"
                    component={PoseEstimationVideoComponent}
                  ></Route>

                  {/* <Route path="/golfSwingImage" component={GolfSwingImageComponent}></Route> */}
                  <Route
                    path="/poseEstimationImage"
                    component={PoseEstimationImageComponent}
                  ></Route>
                  {/* <Route path="/lprImage" component={LprImageComponent}></Route> */}
                  
                  <Route path="/golfDocument" component={GolfDocument}></Route>
                  <Route path="/poseDocument" component={PoseDocument}></Route>
                  <Route path="/golfImageDocument" component={GolfImageDocument}></Route>
                  <Route path="/poseImageDocument" component={PoseImageDocument}></Route>
                  <Route path="/lprImageDocument" component={LprImageDocument}></Route>
                  <Route path="/errorCode" component={ErrorCode}></Route>
                  <Route path="/golfSwingImage" component={GolfSwingImageComponent}></Route>
                  <Route path="/lprImage" component={LprImageComponent}></Route>
                  <Route path="/mecCloud" component={MecCloud}></Route>
                </Switch>
                <Footer />
              </>
            )}
          />
        <Footer />
      

     
    </>
  );
  // }
};

export default Router;

