import React from "react";
const Footer = () => {
  return (
    <div className="footer">
      <div className="footer_inner">
        <div className="footer_logo">
          <img src="img/logo_visionapi_bottom_p.svg" alt="SK telecom" />
        </div>
        <p className="copyright	">
          COPYRIGHT 2021 BY SKTELECOM CO.,LTD. <br className="mb_show" />
          ALL RIGHTS RESERVED
        </p>
      </div>
    </div>
  );
};

export default Footer;
