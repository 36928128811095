import React, { useEffect, useRef, useState } from "react";
import RequestApi from "../../RequestApi";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import DemoSideMenu from "../../common/sideMenu/DemoSideMenu";
import { Link } from "react-router-dom";
import ResponseInfo from "../../common/response/ResponseInfo";
import SyntaxHighlighter from "react-syntax-highlighter";
import axios from "axios";
import { set } from "lodash";
const GolfSwingImageComponent = (match) => {
    let imgCtx = undefined;
    let ctx = undefined;
    const [postData, setpostData] = useState(undefined);
    const body = document.querySelector("body");
    const [postDataResponse, setpostDataResponse] = useState(true);
    const [postDataMeta, setpostDataMeta] = useState(true);
    const [postDatastate, setpostDatastate] = useState(true);
    const [state, setState] = useState(undefined);
    const [slide, setSlide] = useState(true);
    const [data, setData] = useState(null);
    const [errorLog, setErrorLog] = useState(undefined);
    const [canvasWidth, setCanvasWidth] = useState(undefined);
    const [errorState, setErrorState] = useState(undefined);
    const [errorText, setErrorText] = useState(undefined);
    const [requestData, setRequestData] = useState({
      header: {
        appKey: "",
        "Content-Type": "application/json",
      },
      body: { file: undefined },
    });
    const [metaData, setMataData] = useState(undefined);
    const [httpResult, setHttpResult] = useState(undefined);
    const inputFileTag = useRef(null);
    const inputTextTag = useRef(null);
    
    const canvasTag = useRef(null);
    const imageTag = useRef(null);
    const imageReader = new FileReader();
    useEffect(() => {
      if(document.querySelector(".lnb").style.display === 'block'){
        body.style.overflow = "hidden";
      }else{
        body.style.overflow = "";
      }
      a11yDark["hljs"].height = "100%";
    });
    let video = undefined;
    let img = undefined;
    let videoCtx = undefined;
    
    let initFlag = true;
    let guidePoint = undefined;
    let guideType = "";
    const path = match.match.path;
    const formData = new FormData();
  async function analzer() {
    if(document.getElementById("appKey").value === ''){
      alert('appKey를 입력해 주십시오.');
      return false;
    }else if(document.getElementById("file_name").value === ''){
      alert('파일을 업로드해 주십시오.');
      return false;
    }else if(document.querySelector('input[name="imgType"]:checked') === null){
      alert('포지션을 선택해 주십시오');
      return false;
    }else{
    setState(undefined);
    setHttpResult(undefined);
    if(guideType === ""){
      guideType = document.querySelector('input[name="imgType"]:checked').value;
    }
    const file = inputFileTag.current.files[0];
    const apiKey = inputTextTag.current.value;
    const img = imageTag.current;
    

    formData.append("Type", 'Image');
    formData.append("Swing", guideType);
    formData.append("File", file);
    
    const lprData = await axios
      .post(
        process.env.REACT_APP_API_URL + "/golf/v1/e2e", formData,
        {
          headers: { appKey: apiKey },
        },
      )
      .then((data) => {
        if (data.status === 200) {
          setpostDataResponse(data);
          setpostDataMeta(data.data.Result);
          setpostDatastate(data.status);
          return data.data;
        } else {
        }
      })
      .catch((startError) => {
        console.info(startError);
        if(startError.response == undefined){
          setErrorState('404')
          setErrorText('Not Found')
        }else{
          setErrorState(startError.response.status)
          setErrorText(startError.response.statusText)
        }
        return startError;
      });
    //if (lprData.Result.joints.length > 0 || lprData.State !== 'Fail') {
      if (lprData.Result !== undefined) {
      setState("Success");
      guidePoint = lprData.Result
      //setpostDataResponse(lprData.Result)
      const imgCanvas = canvasTag.current;
      imgCtx = imgCanvas.getContext("2d");
      imageReader.onload = async (e) => {
        const img = imageTag.current;
        await imageView(img, e);
        console.info(img.naturalHeight);
        setCanvasWidth(canvasTag.current.width)
        canvasTag.current.height = img.naturalHeight;
        canvasTag.current.width = img.naturalWidth;
        imgCtx.drawImage(img, 0, 0, imageTag.current.naturalWidth, imageTag.current.naturalHeight);
        imgCtx.fillStyle = "red";

        // lprData.Result.joints.forEach((joint) => {
        //   imgCtx.fillRect(joint[0], joint[1], 5, 5);
        // });

        // 머리
        imgCtx.fillStyle = "red";
        imgCtx.beginPath();
        imgCtx.arc(
          guidePoint.head_center[0],
          guidePoint.head_center[1],
          guidePoint.head_circle_radius,
          0,
          2 * Math.PI
        ); //head
        imgCtx.closePath();
        imgCtx.stroke();
      
      imgCtx.fillStyle = "red";
      // guidePoint.joints.forEach((joint) => {
      //   //joint
      //   imgCtx.fillRect(joint[0], joint[1], 5, 5);
      // });
      if (guideType === "Full_Front") {
        drawGuideline(
          guidePoint.body_line_left_top_point,
          guidePoint.body_line_left_bottom_point,
          "green",
          "5"
        ); //body left
        drawGuideline(
          guidePoint.body_line_right_top_point,
          guidePoint.body_line_right_bottom_point,
          "green",
          "5"
        ); // body right
        drawGuideline(
          guidePoint.shoulder_line_left_point,
          guidePoint.shoulder_line_right_point,
          "blue",
          "5"
        ); // shoulder
        drawGuideline(
          guidePoint.hip_ankle_line_lefttop_point,
          guidePoint.hip_ankle_line_leftbottom_point,
          "blue",
          "5"
        ); // ankle left
        drawGuideline(
          guidePoint.hip_ankle_line_righttop_point,
          guidePoint.hip_ankle_line_rightbottom_point,
          "blue",
          "5"
        ); // ankle right
      } else {
        drawGuideline(
          guidePoint.torso_start_point,
          guidePoint.torso_end_point,
          "green",
          "5"
        ); // ankle right
        drawGuideline(
          guidePoint.upleg_start_point,
          guidePoint.upleg_end_point,
          "orange",
          "5"
        ); // ankle right
        drawGuideline(
          guidePoint.lowleg_start_point,
          guidePoint.lowleg_end_point,
          "blue",
          "5"
        ); // ankle right
        drawGuideline(
          guidePoint.neck_ball_line_start_points,
          guidePoint.neck_ball_line_end_points,
          "blue",
          "5"
        ); // ankle right
        drawGuideline(
          guidePoint.hip_ball_line_start_points,
          guidePoint.hip_ball_line_end_points,
          "red",
          "5"
        ); // ankle right
        drawBallLine(
          guidePoint.torso_start_point,
          guidePoint.ball_center,
          "yellow",
          "5"
        );
        drawBallLine(
          guidePoint.torso_end_point,
          guidePoint.ball_center,
          "yellow",
          "5"
        );
      }
      };
      imageReader.readAsDataURL(file);
      
    } else {
      setState("Fail");
      setErrorLog(lprData.Error);
    }
    setRequestData({
      baseUrl: process.env.REACT_APP_API_URL+"/golf/v1/e2e",
      header: {
        appKey: apiKey,
        "Content-Type": "application/json",
      },
      body: {
        file: file.name,
        Type: "Image",
        Swing: guideType,
      },
    });
    setData(lprData);
    setMataData(JSON.stringify(lprData));
  }
}
    
  
    function drawGuideline(startPoint, endPoint, color, lineWidth) {
      // 곡선 , 원 , 포인트
      
        imgCtx.fillRect(startPoint[0], startPoint[1], 10, 10);
        imgCtx.fillRect(endPoint[0], endPoint[1], 10, 10);
        imgCtx.strokeStyle = color;
        imgCtx.lineWidth = lineWidth;
        imgCtx.beginPath();
        imgCtx.moveTo(startPoint[0], startPoint[1]);
        imgCtx.lineTo(endPoint[0], endPoint[1]);
        imgCtx.closePath();
        imgCtx.stroke();
      
    }
  
    function drawBallLine(startPoint, ballPoint, color, lineWidth) {
      //full_back 일때만 공이랑 상체랑 연결
      
        imgCtx.fillRect(startPoint[0], startPoint[1], 10, 10);
        imgCtx.fillRect(ballPoint[0], ballPoint[1], 10, 10);
        imgCtx.strokeStyle = color;
        imgCtx.lineWidth = lineWidth;
        imgCtx.beginPath();
        imgCtx.moveTo(startPoint[0], startPoint[1]);
        imgCtx.lineTo(ballPoint[0], ballPoint[1]);
        imgCtx.closePath();
        imgCtx.stroke();
    
    }
    function radioClear(value){
      document.getElementById('imgType_1').checked = false
      document.getElementById('imgType_2').checked = false
      
      if(value === undefined){
        //document.getElementById("file_name").value = "";
      }else{
        document.getElementById("file_name").value = value.name;
      }
    }
    function imageView(img, e) {
      return new Promise((resolve, reject) => {
        //let img = new Image()
        img.onload = () => resolve(img.height)
        img.onerror = reject
        img.src = e.target.result;
        console.log("width :"+img.width);
        console.log("height :"+img.height)
        // ctx.drawImage(img, 0, 0, img.width, img.height)
      })
    }
    return (
      <section className="container sub_container">
        <button
          className="btn_lnb_open"
          onClick={() => {
            if (slide) {
              document.querySelector(".lnb").style.display = "block";
              document.querySelector(".btn_lnb_open").classList.add("on")
              setSlide(false);
              body.style.overflow = "hidden";
            } else {
              document.querySelector(".lnb").style.display = "none";
              document.querySelector(".btn_lnb_open").classList.remove("on")
              setSlide(true);
              body.style.overflow = "auto";
            }
          }}
        >
          Golf Swing Analyzer
        </button>
        <DemoSideMenu path={path}></DemoSideMenu>
        <div className="subContent">
          <div className="sub_top_title">
            <div className="mb_24">
              <h1>Golf Swing Analyzer</h1>
              <div className="right_btn">
                <Link to="/golfImageDocument" className="btn">
                  API Document
                </Link>
              </div>
            </div>
  
            <p className="txtType_1">
            SKT Pose API 를 이용하여 실내 스크린 골프 존 내 스윙 영상을 분석해서 골프 스윙 준비 자세를 추출하고, 준비 자세가 알맞도록 사람의 머리, 관절, 골프 공 등을 추출해 Guideline 을 제공합니다
            </p>
            <div className="tooltip">
            최대 용량 3MB / 해상도 640 ~ 1024 pixel인 이미지만 분석이 가능합니다. 
            또한 스크린 골프장 이미지에 적합하게 설계되어, 골프 스윙 준비 자세가 아닌 이미지, 야외, 
            회전된 이미지 등은 Guideline이 제대로 추출되지 않을 수 있습니다. 
            jpg, bmp, png 확장자 이외의 이미지는 결과가 제대로 추출되지 않을 수 있습니다.
            </div>
          </div>
  
          <h2 className="titleType_1">요청</h2>
          <div className="listType_4">
            <ul>
              <li>
                <label htmlFor="appKey" className="title">
                  appKey
                </label>
                <input type="text" id="appKey" ref={inputTextTag} />
              </li>
              <li>
                <span className="title">File</span>
                <div className="right_btn">
                  <input type="text" id="file_name" disabled/>
                  <input type="file" id="file_upload" accept=".jpg, .png" style={{ display: "none" }} ref={inputFileTag} onChange={(e)=> radioClear(e.target.files[0])}/>
                  <label htmlFor="file_upload" className="btn">
                    Upload
                  </label>
                </div>
              </li>
              <li>
                <span className="title">Image</span>
                <span className="custom_radio">
                  <input
                    value="Full_Front"
                    type="radio"
                    id="imgType_1"
                    name="imgType"
                    onChange={(event) => {
                      guideType = event.target.value;
                      
                    }}
                  />
                  <label htmlFor="imgType_1">Front</label>
                </span>
                <span className="custom_radio">
                  <input
                    value="Full_Back"
                    type="radio"
                    id="imgType_2"
                    name="imgType"
                    onChange={(event) => {
                      guideType = event.target.value;
                    }}
                  />
                  <label htmlFor="imgType_2">Back</label>
                </span>
              </li>
            </ul>
          </div>
          <div className="txt_r">
            <input
              type="submit"
              value="전송"
              className="basic-btn01 btn-red-bg"
              onClick={analzer}
            />
          </div>
        </div>
        {state === undefined ? (
          <></>
        ) : state === "Success" ? (
          <div className="sub_box">
            <div className="title_area">
              <h2>응답</h2>
            </div>
            <div className="sub_box_content">
              <h3 className="titleType_2">분석 결과</h3>
              <div className="img_list">
              <div style={{display:"none"}}>
                      <img ref={imageTag} />
              </div>
              <ul>
                <li>
                  <div className="img_area">
                    <canvas
                      ref={canvasTag}
                      // style={{ width: "640px", height: "357px" }}
                      style={{ width: canvasWidth }}
                      className="canvas"
                    ></canvas>
                  </div>
                  <div className="txtArea">분석 이미지</div>
                </li>
              </ul>
              <h3 className="titleType_4">Meta in json</h3>
              <div className="codeArea">
                    <SyntaxHighlighter
                      language="json"
                      style={a11yDark}
                      showLineNumbers
                    >
                      {`${JSON.stringify(postDataMeta, null, "  ")}`}
                    </SyntaxHighlighter>
              </div>  
            </div>
            <h3 className="titleType_4 big">End2End API</h3>
              <div className="top_right_btn_area">
                <h4 className="titleType_2">Request</h4>
                <div className={`state ` + postDatastate}>
                  <span className="number">200</span>
                  <div className="txt">{state}</div>
                </div>
              </div>
              <div className="codeArea mb_16">
                  <SyntaxHighlighter
                    language="json"
                    style={a11yDark}
                    showLineNumbers
                  >
                    {`${JSON.stringify({requestData}, null, "  ")}`}
                  </SyntaxHighlighter>
              </div>

              <div className="top_right_btn_area">
                <h4 className="titleType_2">Response</h4>
              </div>
              <div className="codeArea">
                  <SyntaxHighlighter
                    language="json"
                    style={a11yDark}
                    showLineNumbers
                  >
                    {`${JSON.stringify(postDataResponse, null, "  ")}`}
                  </SyntaxHighlighter>
              </div>
          </div>
        </div>
        ) : (
          <div className="sub_box">
            <div className="title_area">
              <h2>응답</h2>
            </div>
            <div className="sub_box_content">
              <h3 className="titleType_2">분석 결과</h3>
  
              <div className="analysis_result">
                <div className="no_result">
                  <p className="error_txt">
                    이미지가 분석되지 않았습니다. <br />
                    다시 시도해 주십시오.
                    <span>{`(Response Code :${errorState})`}</span>
                  </p>
                </div>
              </div>
  
              
              <div className="top_right_btn_area">
                <h4 className="titleType_2">Meta in json</h4>
              </div>
              <div className="codeArea mb_50">
              <SyntaxHighlighter
                  language="json"
                  style={a11yDark}
                >
                  {`{}`}
                </SyntaxHighlighter>
              </div>
              <h3 className="titleType_4 big">End2End API</h3>
              <div className="top_right_btn_area">
                <h4 className="titleType_2">Request</h4>
                <div className={`state red`}>
                  <span className="number">{errorState}</span>
                  <div className="txt">{errorText}</div>
                </div>
              </div>
              
              <div className="codeArea mb_16">
                  <SyntaxHighlighter
                    language="json"
                    style={a11yDark}
                    showLineNumbers
                  >
                    {`${JSON.stringify({requestData}, null, "  ")}`}
                  </SyntaxHighlighter>
              </div>

              <div className="top_right_btn_area">
                <h4 className="titleType_2">Response</h4>
              </div>
              <div className="codeArea">
                  <SyntaxHighlighter
                    language="json"
                    style={a11yDark}
                    showLineNumbers
                  >
                    {`{}`}
                  </SyntaxHighlighter>
              </div>
            </div>
          </div>
        )}
        <ResponseInfo
          httpResult={httpResult}
          requestData={requestData}
          type="golf"
        ></ResponseInfo>
      </section>
    );
  };
  
  export default GolfSwingImageComponent;