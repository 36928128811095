import { Link } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import DocumentSideMenu from "../../common/sideMenu/DocumentSideMenu";
import DemoSideMenu from "../../common/sideMenu/DemoSideMenu";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import SyntaxHighlighter from "react-syntax-highlighter";
const LprImageDocument = (match) => {
  const path = match.match.path;
  const [state, setState] = useState(undefined);
  const [slide, setSlide] = useState(true);
  const body = document.querySelector("body");
  useEffect(() => {
    if(document.querySelector(".lnb").style.display === 'block'){
      body.style.overflow = "hidden";
    }else{
      body.style.overflow = "";
    }
  });
  return (
    <section className="container sub_container">
      <button
        className="btn_lnb_open"
        onClick={() => {
          if (slide) {
            document.querySelector(".lnb").style.display = "block";
            document.querySelector(".btn_lnb_open").classList.add("on")
            setSlide(false);
            body.style.overflow = "hidden";
          } else {
            document.querySelector(".lnb").style.display = "none";
            document.querySelector(".btn_lnb_open").classList.remove("on")
            setSlide(true);
            body.style.overflow = "";
          }
        }}
      >License Plate Recognition</button>
      <DocumentSideMenu
        path={path}
        routeLink="/LprImageDocument"
      ></DocumentSideMenu>
      <div className="subContent">
      <div className="sub_top_title">
            <h1>License Plate Recognition</h1>
            <div className="right_btn">
            <Link to="/lprImage" className="btn">
              API Demo
            </Link>
            </div>
        </div>
        <p className="txtType_1 mb_50">
            이미지를 분석하여 차량 번호판을 검출하고 인식하여 차량 번호를 추출합니다.
        </p>

        <h2 className="titleType_1">제공기능</h2>
        <div className="listType_1">
            <ul>
                <li>
                    <div className="title">
                        <span>lpr</span>
                    </div>
                    <div className="txt">
                        <div className="inner">이미지 내 차량의 번호판을 인식하여 번호판 좌표 및 차량번호를 json으로 추출하는 API</div>
                    </div>
                </li>
            </ul>
        </div>

        <h2 className="titleType_1">주의사항</h2>
        <div className="listType_2 mb_50">
            <ul>
                <li>3MB 이하의 차량의 이미지 분석만 가능합니다.</li>
            </ul>
        </div>

        <h2 className="titleType_1">개발가이드</h2>
        <p className="txtType_1">SDK 를 지원하지 않으며 HTTP API 방식으로 구현할 수 있습니다.</p>
        <div className="listType_2 mb_50">
            <ul>
                <li>요청 시 HTTP Connection Timeout은 5초, read TimeOut 은 10초로 설정합니다.</li>
                <li>과도하게 많은 요청을 보내어 초당 요청 한도를 초과하는 경우 오류 응답을 받을 수 있습니다.</li>
                <li>이미지 해상도는 최소 640 pixel, 최대 1024 pixel에 최적화되어 이외에는 결과가 제대로 추출되지 않을 수 있습니다.</li>
            </ul>
        </div>

        <h2 className="titleType_1">LPR 분석 API(LPR API)</h2>
        <p className="txtType_1">입출차 차량에 대한 이미지를 분석하여 번호판 좌표와 차량 번호를 JSON 형태로 반환하는 API입니다.</p>
        <div className="listType_2">
            <ul>
                <li>URL : /sigmeta/lpr/v1/</li>
                <li>Method : POST</li>
                <li>Request</li>
            </ul>
        </div>

        <h3 className="titleType_2">Header</h3>
        <div className="tableType_2">
            <table>
                <colgroup>
                <col style={{ width: "137px" }} />
                <col style={{ width: "96px" }} />
                <col style={{ width: "490px" }} />
                <col style={{ width: "154px" }} />
                </colgroup>
                <thead>
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Type</th>
                        <th scope="col">Description</th>
                        <th scope="col">Required</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>App Key</td>
                        <td>String</td>
                        <td>
                            SK Open API의 App Key <br/>
                            My Project > Key > Project Key (App Key)
                        </td>
                        <td>
                            Required <br/>
                        </td>
                    </tr>
                    <tr>
                        <td>Content-type</td>
                        <td>multipart/form-data</td>
                        <td></td>
                        <td>Required</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <h3 className="titleType_2">Parameter</h3>
        <div className="tableType_2">
            <table>
                <colgroup>
                <col style={{ width: "137px" }} />
                <col style={{ width: "96px" }} />
                <col style={{ width: "206px" }} />
                <col style={{ width: "440px" }} />
                </colgroup>
                <thead>
                    <tr>
                        <th scope="col">Key</th>
                        <th scope="col">Value</th>
                        <th scope="col">Type</th>
                        <th scope="col">Description</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>File</td>
                        <td>.jpg</td>
                        <td>Multibyte form</td>
                        <td>이미지 파일</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <h3 className="titleType_2">Request Body Example</h3>
        <div className="ex_code mb_24">
<SyntaxHighlighter
                language="json"
                style={a11yDark}
                showLineNumbers
              >
                {`curl -X "POST" 
"https://apis.openapi.sk.com/sigmeta/lpr/v1/"
-H "accept: application/json" 
-H "appKey: appKey" 
-H "Content-Type: multipart/form-data"
-F "File=@lpr.png;type=image/png"`}
              </SyntaxHighlighter>
        </div>

        <h4 className="titleType_3">Response</h4>
        <div className="tableType_2">
            <table>
                <colgroup>
                <col style={{ width: "137px" }} />
                    <col style={{ width: "96px" }} />
                    <col style={{ width: "647px" }} />
                </colgroup>
                <thead>
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Type</th>
                        <th scope="col">Description</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Prepare images</td>
                        <td>Time</td>
                        <td>Image processing time (in msec)</td>
                    </tr>
                    <tr>
                        <td>Inference</td>
                        <td>Time</td>
                        <td>LPR Analysis time (in msec)</td>
                    </tr>
                    <tr>
                        <td>All</td>
                        <td>Time</td>
                        <td>Total processed time </td>
                    </tr>
                    <tr>
                        <td>Lp_string</td>
                        <td>String</td>
                        <td>인식된 License Plate Number </td>
                    </tr>
                    <tr>
                        <td>Lp_keypoints</td>
                        <td>Float</td>
                        <td>X,Y (top-left, top-right, bottom-right, bottom-left) </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <h3 className="titleType_2">Success Response Example</h3>
        <div className="ex_code">
    <SyntaxHighlighter
                language="json"
                style={a11yDark}
                showLineNumbers
              >
                {`{
    "performance": {
        "prepare image": " 8.66ms",
        "inference": " 145.99ms",
        "all": " 154.73ms"
    },
    "result": {
        "objects": [
            {
                "lp_string": "166ho4570",
                "lp_keypoints": [
                    [
                        382.0,
                        449.0
                    ],
                    [
                        540.0,
                        448.0
                    ],
                    [
                        545.0,
                        479.0
                    ],
                    [
                        394.0,
                        484.0
                    ]
                ]
            }
        ]
    }
}`}
              </SyntaxHighlighter>
        </div>
    </div>
        </section>
  );
};
export default LprImageDocument;
