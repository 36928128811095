import { Link } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import DocumentSideMenu from "../../common/sideMenu/DocumentSideMenu";
import DemoSideMenu from "../../common/sideMenu/DemoSideMenu";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import SyntaxHighlighter from "react-syntax-highlighter";
const GolfImageDocument = (match) => {
  const [state, setState] = useState(undefined);
  const [slide, setSlide] = useState(true);
  const body = document.querySelector("body");
  const path = match.match.path;
  useEffect(() => {
    if(document.querySelector(".lnb").style.display === 'block'){
      body.style.overflow = "hidden";
    }else{
      body.style.overflow = "";
    }
  });
  return (
    <section className="container sub_container">
      <button
        className="btn_lnb_open"
        onClick={() => {
          if (slide) {
            document.querySelector(".lnb").style.display = "block";
            document.querySelector(".btn_lnb_open").classList.add("on")
            setSlide(false);
            body.style.overflow = "hidden";
            } else {
            document.querySelector(".lnb").style.display = "none";
            document.querySelector(".btn_lnb_open").classList.remove("on")
            setSlide(true);
            body.style.overflow = "";
          }
        }}
      >Golf Swing Analyzer</button>
      <DocumentSideMenu
        path={path}
        routeLink="/GolfImageDocument"
      ></DocumentSideMenu>
      <div className="subContent">
        <div className="sub_top_title">
          <h1>Golf Swing Analyzer</h1>
          <div className="right_btn">
            <Link to="/golfSwingImage" className="btn">
              API Demo
            </Link>
          </div>
        </div>
        <p className="txtType_1 mb_50">
            SKT Pose API를 이용하여 실내 스크린 골프 존 내 스윙 영상을 분석해서 골프 스윙 준비 자세의 프레임을 추출하고, 해당 프레임 내 사람의 머리, 관절, 골프 공 등을
            추출해 Guideline을 제공합니다.
        </p>

        <h2 className="titleType_1">제공기능</h2>
        <div className="listType_1">
            <ul>
                <li>
                    <div className="title">
                        <span>e2e</span>
                    </div>
                    <div className="txt">
                        <div className="inner">HTTP Protocol로 3MB 이하의 이미지 파일의 업로드와 분석을 한번에 처리하여 JSON 형태로 반환하는 API
                        </div>
                    </div>
                </li>
            </ul>
        </div>

        <h2 className="titleType_1">주의사항</h2>
        <div className="listType_2 mb_50">
            <ul>
                <li>최대 3MB 용량의 이미지 분석이 가능합니다.</li>
                <li>또한 스크린 골프장 영상에 적합하게 설계되어, 골프 스윙 준비 자세가 아닌 이미지, 야외, 회전된 이미지 등은 Guideline 이 제대로 추출되지 않을 수 있습니다.</li>
            </ul>
        </div>

        <h2 className="titleType_1">개발가이드</h2>
        <p className="txtType_1">SDK를 지원하지 않으며 HTTP API 방식으로 구현할 수 있습니다.</p>
        <div className="listType_2 mb_50">
            <ul>
                <li>요청 시 HTTP Connection Timeout은 5초, read TimeOut 은 20초로 설정합니다.</li>
                <li>과도하게 많은 요청을 보내어 초당 요청 한도를 초과하는 경우 오류 응답을 받을 수 있습니다.</li>
                <li>이미지 해상도는 최소 640 pixel, 최대 1024 pixel에 최적화되어 이외에는 결과가 제대로 추출되지 않을 수 있습니다. </li>
            </ul>
        </div>

        <h2 className="titleType_1">이미지 분석 API (End2End API)</h2>
        <p className="txtType_1">
            HTTP Protocol 로 3MB 이하의 이미지 파일의 업로드와 분석을 한번에 처리하여 JSON 형태로 반환하는 API입니다.
            이미지 분석이 성공적으로 완료되면 골프 스윙 준비 자세 프레임과 해당 프레임에서 가장 큰 사람의 관절 좌표가 JSON 객체로 반환합니다.
        </p>
        <div className="listType_2">
            <ul>
                <li>URL : /sigmeta/golf/v1/e2e </li>
                <li>Method : POST</li>
                <li>Request</li>
            </ul>
        </div>
        <h3 className="titleType_2">Header</h3>
        <div className="tableType_2">
            <table>
                <colgroup>
                    <col style={{width:"137px"}}/>
                    <col style={{width:"96"}}/>
                    <col style={{width:"206"}}/>
                    <col style={{width:"440"}}/>
                </colgroup>
                <thead>
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Type</th>
                        <th scope="col">Description</th>
                        <th scope="col">Required</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Content-type</td>
                        <td>String</td>
                        <td>application/json</td>
                        <td>Required</td>
                    </tr>
                    <tr>
                        <td>App Key</td>
                        <td>string</td>
                        <td>
                            SK Open API의 App Key <br/>
                            My Project > Key > Project Key (App Key)
                        </td>
                        <td>
                            Required <br/>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <h3 className="titleType_2">Parameter</h3>
        <div className="tableType_2">
            <table>
                <colgroup>
                    <col style={{width:"137px"}}/>
                    <col style={{width:"96px"}}/>
                    <col style={{width:"206px"}}/>
                    <col style={{width:"440px"}}/>
                </colgroup>
                <thead>
                    <tr>
                        <th scope="col">Key</th>
                        <th scope="col">Value</th>
                        <th scope="col">Type</th>
                        <th scope="col">Description</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Type</td>
                        <td>Image</td>
                        <td>String</td>
                        <td>‘Image'</td>
                    </tr>
                    <tr>
                        <td>Swing</td>
                        <td>Full_Front</td>
                        <td>String</td>
                        <td>'Full_Front' or 'Full_Back'</td>
                    </tr>
                    <tr>
                        <td>File</td>
                        <td>.png</td>
                        <td>Multibyte form</td>
                        <td>
                            지원 확장자 : jpg, png, bmp <br/>
                            *3MB 이하의 파일만 분석 가능합니다.
                        </td>
                    </tr>
                    <tr>
                        <td>Error</td>
                        <td></td>
                        <td>String</td>
                        <td>Status Fail 시 Error 내용</td>
                    </tr>
                    
                </tbody>
            </table>
        </div>
        <h3 className="titleType_2">Request Body Example</h3>
        <div className="ex_code mb_32">
<SyntaxHighlighter
                language="json"
                style={a11yDark}
                showLineNumbers
              >
                {`POST https://apis.openapi.sk.com/sigmeta/golf/v1/e2e
headers: {
"content-type": "application/json",
"appKey": appKey
},
body: json {
"Type": "Guideline",
"Swing": "Full_Front"
“File”: file
}'`}
              </SyntaxHighlighter>
        </div>

        <h3 className="titleType_2">Success Response Example</h3>
        <div className="ex_code">
        <SyntaxHighlighter
                language="json"
                style={a11yDark}
                showLineNumbers
              >
                {`'"State": "Success", "Result": {"head_center": [-1, -7], "head_circle_radius": [22], "hip_ankle_line_lefttop_point": [-1,
-1], "hip_ankle_line_leftbottom_point": [332, 396], "hip_ankle_line_righttop_point": [-1, -1],
"hip_ankle_line_rightbottom_point": [-1, -1], "body_line_left_top_point": [-14, -27], "body_line_left_bottom_point":
[-14, 413], "body_line_right_top_point": [12, -27], "body_line_right_bottom_point": [12, 413],
"shoulder_line_left_point": [-14, 0], "shoulder_line_right_point": [12, 0], "address_frame_num": [38.0], "joints":
[[-1.0, -1.0, -1.0], [-1.0, -1.0, -1.0], [-1.0, -1.0, -1.0], [-1.0, -1.0, -1.0], [-1.0, -1.0, -1.0], [-1.0, -1.0, -1.0],
[-1.0, -1.0, -1.0], [-1.0, -1.0, -1.0], [-1.0, -1.0, -1.0], [-1.0, -1.0, -1.0], [-1.0, -1.0, -1.0], [332.0, 396.0, 0.1],
[-1.0, -1.0, -1.0], [-1.0, -1.0, -1.0], [-1.0, -1.0, -1.0], [-1.0, -1.0, -1.0], [-1.0, -1.0, -1.0], [-1.0, -1.0, -1.0],
[-1.0, -1.0, -1.0], [-1.0, -1.0, -1.0], [-1.0, -1.0, -1.0], [-1.0, -1.0, -1.0], [288.0, 424.0, 0.3], [288.0, 416.0,
0.2], [528.0, 168.0, 0.1]]}} }'`}
              </SyntaxHighlighter>
        </div>

        <h3 className="titleType_2">Result Image Example</h3>
        
        <div className="txt_c mb_50">
          <img src="../img/img_golf_result_p.jpg" alt="" />
        </div>
        
    </div>
    </section>
  );
};
export default GolfImageDocument;
