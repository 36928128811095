window.onload = () => {
  // if(window.location.pathname === '/'){
  //   document.getElementsByClassName('page_back')[0].remove();
  //   //document.getElementsByClassName('logo')[0].style.display = 'block'
  // }
  const body = document.querySelector("body");
  document.querySelector(".btn_menu_open").addEventListener("click", () => {
    //햄버거 바
    document
      .querySelector(".gnb")
      .animate([{ left: 0 }], { duration: 300, fill: "forwards" });
      body.style.overflow = "hidden";
    return false;
  });
  
  document.querySelector(".btn_gnb_close").addEventListener("click", () => {
    document
      .querySelector(".gnb")
      .animate({ left: "-120%" }, { duration: 300, fill: "forwards" });
      body.style.overflow = "";
    return false;
  });
  document.getElementById("close1").addEventListener("click", () => {
    document
      .querySelector(".gnb")
      .animate({ left: "-120%" }, { duration: 300, fill: "forwards" });
      body.style.overflow = "";
    return false;
  });
  document.getElementById("close2").addEventListener("click", () => {
    document
      .querySelector(".gnb")
      .animate({ left: "-120%" }, { duration: 300, fill: "forwards" });
      body.style.overflow = "";
    return false;
  });

  // if (document.querySelector(".btn_lnb_open") !== null) {
  //   document.querySelector(".btn_lnb_open").addEventListener("click", () => {
  //     //하단 바
  //     const lnb = document.querySelector(".lnb").style.display;
  //     const slide = document.querySelector(".lnb");
  //     const body = document.querySelector("body");
  //     console.info(lnb);
  //     if (lnb === "") {
  //       slide.style.display = "block";
  //       slide.style.height = "auto";
  //       const height = slide.clientHeight + "px";
  //       slide.style.height = "0px";
  //       setTimeout(() => {
  //         slide.style.height = height;
  //       }, 0);
  //       slide.className = slide.className + " on";
  //       body.style.overflow = "hidden";
  //     } else {
  //       slide.style.height = "0px";
  //       slide.className = "lnb";
  //       body.style = "";
  //       slide.style.display = "";
  //       body.style.overflow = "auto";
  //       slide.style.display = "";
  //       // slide.addEventListener(
  //       //   "transitionend",
  //       //   () => {
  //       //     slide.style.display = "none";
  //       //   },
  //       //   { once: true }
  //       // );
  //     }
  //   });
  // }

  // if (document.querySelector(".open_slnb") !== null) {
  //   console.info(document.querySelector(".open_slnb"));
  // }
  // $(".btn_lnb_open").on("click", function () {

  //   var lnb = $(".lnb").css("display");

  //   if (lnb == "none") {

  //     $(".lnb").slideDown("200");

  //     $(this).addClass("on");

  //     $("body").css("overflow", "hidden");

  //   } else {
  //     $(".lnb").slideUp("200");
  //     $(this).removeClass("on");
  //     $("body").removeAttr("style");
  //   }
  // });

  // if (win_w >= 1024) {
  //   $(".lnb").show();
  // } else {
  //   $(".lnb").hide();
  // }

  // $(window).resize(function () {
  //   var win_w = $(window).width();
  //   if (win_w >= 1024) {
  //     $(".lnb").show();
  //   } else {
  //     $(".lnb").hide();
  //   }
  // });
  // });
};