// const test = (param) => {
  function sliderOnOff(status, tag) {
    if (status) {
      tag.className = "lnb_on";
    } else {
      tag.className = "";
    }
  }
  
  function search(keyword, exposeMenu) {
    const keywordToLower = keyword.toLowerCase();
    const exposeMenuList = [];
  
    const menuList = exposeMenu; //cloneDeep(exposeMenu);
    // [
    //   {
    //     parantMenu: "Video API",
    //     childMenu: ["Golf Swing Analyzer", "Pose Estimation"],
    //     type: "video",
    //   },
    //   {
    //     parantMenu: "Image API",
    //     childMenu: [
    //       "Golf Swing Analyzer",
    //       "Pose Estimation",
    //       "License Plate Recognition",
    //     ],
    //     type: "image",
    //   },
    //   {
    //     parantMenu: "MEC Cloud",
    //     childMenu: ["Golf Swing Analyzer"],
    //     type: "mec",
    //   },
    // ];
    menuList.forEach((menu) => {
      const child = [];
      menu.childMenu.forEach((name) => {
        if (name.category.toLowerCase().indexOf(keywordToLower) !== -1) {
          child.push({ category: name.category, url: name.url });
        }
      });
      if (child.length >= 1) {
        exposeMenuList.push({
          parantMenu: menu.parantMenu,
          childMenu: child,
          type: menu.type,
        });
      }
    });
    if (keyword === "") {
      return menuList;
    } else {
      return exposeMenuList;
    }
  }
  // };
  
  export { sliderOnOff, search };
  
