import React from "react";
import { useLocation } from "react-router-dom";

const Main = () => {
  return (
    <>
      <section className="container">
        <div className="visual">
          <div className="visual_txt">
            <h1>META API</h1>
            <p>
              <br />
               META API는 사람이 눈으로 보고 이해할 수 있는 <br/>
               SKT의 핵심 Vision AI 요소 기술들이 집합된 플랫폼입니다.<br className="mb_hide" />
               <br/>
               
              영상이나 이미지의 특성을 분석하여 {" "} <br />
              사람 혹은 물체, 행동 및 상황을 인식하는 알고리즘을 외부와 API 형태로 공유합니다.  <br className="mb_hide" />
              <br/>
              
              동영상에 특화된 딥러닝 기반의 서비스 플랫폼으로 <br />
              Security, Industry, Media, Medical 등 다양한 ICT 영역에 기술을 적용하고 있습니다. <br />
            </p>
            <div className="btn_area">
              <a href="/golfSwingVideo">META API 체험하기</a>
            </div>
          </div>
        </div>
        <div className="video_wrap">
          <div className="video_area">
            <div className="inner">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/t9Gc5RpXJ-Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            {/* <video src="video/video.mp4" width="560" height="315" autoPlay="autoplay" loop="loop" controls="controls"></video> */}
            </div>
          </div>
        </div>

        <div className="main_cont">
          <h1>
            주요기능 <span className="en_title">Major function</span>
          </h1>
          <ul className="main_list">
            <li>
              <div className="img_area">
               
                  <img src="../img/img_mf_01pc.jpg" alt="" className="web" />
                  <img
                    src="../img/image_tab_main_sub01.jpg"
                    alt=""
                    className="tab"
                  />
              
              </div>
              <div className="txt_area">
                <strong className="title">Golf Swing Analyzer</strong>
                <div className="txt">
                  Golf Swing Analyzer API는 SKT Pose API를 이용하여 실내 스크린
                  골프존 내 스윙 영상을 분석해서 골프 스윙 준비 자세를 추출하고,
                  준비자세가 알맞도록 사람의 머리, 관절, 골프공 등을 추출해
                  Guideline을 제공합니다.
                </div>
                <div className="link">
                  <a href="golfSwingVideo">
                    <span>Demo Page</span>
                  </a>
                </div>
              </div>
            </li>
            <li>
              <div className="img_area">
                
                  <img src="img/img_mf_02pc.jpg" alt="" className="web" />
                  <img
                    src="img/image_tab_main_sub02.jpg"
                    alt=""
                    className="tab"
                  />
                
              </div>
              <div className="txt_area">
                <strong className="title">Pose Estimation</strong>
                <div className="txt">
                  Pose Estimation API는 이미지나 영상을 분석해 사람의 머리, 눈,
                  코, 손목, 무릎 등 25개의 관절 포인트를 추출하는 기능을
                  제공합니다.
                </div>
                <div className="link">
                  <a href="/PoseEstimationVideo">
                    <span>Demo Page</span>
                  </a>
                </div>
              </div>
            </li>
            <li>
              <div className="img_area">
                
                  <img src="img/img_mf_03pc.jpg" alt="" className="web" />
                  <img
                    src="img/image_tab_main_sub03.png"
                    alt=""
                    className="tab"
                  />
                
              </div>
              <div className="txt_area">
                <strong className="title">License Plate Recognition</strong>
                <div className="txt">
                  License Plate Recognition API는 차량의 번호판을 인식 하여
                  번호판 좌표 및 차량번호를 제공합니다. 
                </div>
                <div className="link">
                  <a href="/lprImage">
                    <span>Demo Page</span>
                  </a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default Main;