import React, { useEffect, useRef, useState } from "react";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import DemoSideMenu from "../../common/sideMenu/DemoSideMenu";
import { Link } from "react-router-dom";
import kpop from "../../common/js/kpop";
import SyntaxHighlighter from "react-syntax-highlighter";
import axios from "axios";
import ResponseInfo from "../../common/response/ResponseInfo";
const PoseEstimationImageComponent = (match) => {
  const [state, setState] = useState(undefined);
  const [slide, setSlide] = useState(true);
  const [postDataMeta, setpostDataMeta] = useState(true);
  const [postDataResponse, setpostDataResponse] = useState(true);
  const [postDatastate, setpostDatastate] = useState(true);
  const [requestData, setRequestData] = useState({
    header: {
      appKey: "",
      "Content-Type": "application/json",
    },
    body: { file: undefined },
  });
  const [metaData, setMataData] = useState(undefined);
  const [httpResult, setHttpResult] = useState(undefined);
  const [errorState, setErrorState] = useState(undefined);
  const [errorText, setErrorText] = useState(undefined);
  const [errorLog, setErrorLog] = useState(undefined);
  const inputFileTag = useRef(null);
  const inputTextTag = useRef(null);
  const canvasTag = useRef(null);
  const imageTag = useRef(null);
  const [data, setData] = useState(null);
  const [canvasWidth, setCanvasWidth] = useState(undefined);
  const [canvasHeight, setCanvasHeight] = useState(undefined);
  const body = document.querySelector("body");

  let img = undefined;
  let ctx = undefined;
  
  let poseEstimationJoint = undefined;
  
  const imageReader = new FileReader();
  const path = match.match.path;
  useEffect(() => {
    if(document.querySelector(".lnb").style.display === 'block'){
      body.style.overflow = "hidden";
    }else{
      body.style.overflow = "";
    }
    a11yDark["hljs"].height = "100%";
  });
  let guidePoint = undefined;

  const formData = new FormData();
  async function analzer() {
    if(document.getElementById("appKey").value === ''){
      alert('appKey를 입력해 주십시오.');
      return false;
    }else if(document.getElementById("file_name").value === ''){
      alert('파일을 업로드해 주십시오.');
      return false;
    }else{
    setState(undefined);
    setHttpResult(undefined);
    const file = inputFileTag.current.files[0];
    const apiKey = inputTextTag.current.value;
    const img = imageTag.current;

    formData.append("Type", 'PoseEstimation');
    formData.append("Swing", 'Full_Front');
    formData.append("File", file);
    
    const lprData = await axios
      .post(
        process.env.REACT_APP_API_URL +"/pose/v1/e2e", formData,
        {
          headers: { appKey: apiKey },
        },
      )
      .then((data) => {
        if (data.status === 200) {
          setpostDataResponse(data);
          setpostDatastate(data.status);
          setpostDataMeta(data.data.Result);
          return data.data;
        } else {
        }
      })
      .catch((startError) => {
        console.info(startError);
        setErrorState(startError.response.status)
        setErrorText(startError.response.statusText)
        return startError;
      });
    //if (lprData.Result.list_of_joints[0].length > 0) {
      if (lprData.Result !== undefined) {
      setState("Success");
      ctx = canvasTag.current.getContext("2d");

      imageReader.onload = async (e) => {
        const img = imageTag.current;
        await imageView(img, e);
        console.info(canvasTag.current.width);
        console.info(canvasTag.current.height);

        //canvasHeight = document.getElementsByClassName('img_area')[0].clientHeight;
        //canvasWidth = document.getElementsByClassName('img_area')[0].clientWidth;
        setCanvasWidth(canvasTag.current.width)
        setCanvasHeight(canvasTag.current.height)
        canvasTag.current.height = img.naturalHeight;
        canvasTag.current.width = img.naturalWidth;
        ctx.drawImage(img, 0, 0, canvasTag.current.width, canvasTag.current.height);
        ctx.fillStyle = "red";
        

        for (let i = 0; i < lprData.Result.list_of_joints[0].length; i++) {
          lprData.Result.list_of_joints[0][i].forEach((joint) => {
            ctx.fillRect(joint[0], joint[1], 5, 5);
          });

            drawGuideline(
              lprData.Result.list_of_joints[0][i][2],
              lprData.Result.list_of_joints[0][i][3],
              "blue",
              "8"
            );
            drawGuideline(
              lprData.Result.list_of_joints[0][i][2],
              lprData.Result.list_of_joints[0][i][5],
              "blue",
              "8"
            );
            drawGuideline(
              lprData.Result.list_of_joints[0][i][2],
              lprData.Result.list_of_joints[0][i][9],
              "blue",
              "8"
            );
            drawGuideline(
              lprData.Result.list_of_joints[0][i][3],
              lprData.Result.list_of_joints[0][i][4],
              "blue",
              "8"
            );
            drawGuideline(
              lprData.Result.list_of_joints[0][i][5],
              lprData.Result.list_of_joints[0][i][6],
              "blue",
              "8"
            );
            drawGuideline(
              lprData.Result.list_of_joints[0][i][5],
              lprData.Result.list_of_joints[0][i][12],
              "blue",
              "8"
            );
            drawGuideline(
              lprData.Result.list_of_joints[0][i][6],
              lprData.Result.list_of_joints[0][i][7],
              "blue",
              "8"
            );
            drawGuideline(
              lprData.Result.list_of_joints[0][i][9],
              lprData.Result.list_of_joints[0][i][10],
              "blue",
              "8"
            );
            drawGuideline(
              lprData.Result.list_of_joints[0][i][9],
              lprData.Result.list_of_joints[0][i][12],
              "blue",
              "8"
            );
            drawGuideline(
              lprData.Result.list_of_joints[0][i][10],
              lprData.Result.list_of_joints[0][i][11],
              "blue",
              "8"
            );
            drawGuideline(
              lprData.Result.list_of_joints[0][i][11],
              lprData.Result.list_of_joints[0][i][22],
              "blue",
              "8"
            );
            drawGuideline(
              lprData.Result.list_of_joints[0][i][12],
              lprData.Result.list_of_joints[0][i][13],
              "blue",
              "8"
            );
            drawGuideline(
              lprData.Result.list_of_joints[0][i][13],
              lprData.Result.list_of_joints[0][i][14],
              "blue",
              "8"
            );
            drawGuideline(
              lprData.Result.list_of_joints[0][i][14],
              lprData.Result.list_of_joints[0][i][19],
              "blue",
              "8"
            );
        }
      };
      imageReader.readAsDataURL(file);
    } else {
      setState("Fail");
      setErrorLog(lprData.Error);
    }
    setRequestData({
      baseUrl: process.env.REACT_APP_API_URL+"/pose/v1/e2e",
      header: {
        appKey: apiKey,
        "Content-Type": "application/json",
      },
      body: {
        file: file.name,
        Type: "PoseEstimation",
        Swing: "Full_Front",
      },
    });
    setData(lprData);
    setMataData(JSON.stringify(lprData));
  }
}
  function drawGuideline(startPoint, endPoint, color, lineWidth) {
    // 곡선 , 원 , 포인트를 그리는 함수
    if(startPoint[0] > 0 && startPoint[1] > 0 && endPoint[0] > 0 && endPoint[1] > 0){
      ctx.fillRect(startPoint[0], startPoint[1], 10, 10);
      ctx.fillRect(endPoint[0], endPoint[1], 10, 10);
      ctx.strokeStyle = color;
      ctx.lineWidth = lineWidth;
      ctx.beginPath();
      ctx.moveTo(startPoint[0], startPoint[1]);
      ctx.lineTo(endPoint[0], endPoint[1]);
      ctx.closePath();
      ctx.stroke();
    }
  }

  
    // function show(file) {
    //   var reader = new FileReader();
    //   reader.onload = (function (theFile) {
    //     return function (e) {
    //       var buffer = e.target.result;
    //       var tiff = new Tiff({buffer: buffer});
    //       var canvas = tiff.toCanvas();
    //       var width = tiff.width();
    //       var height = tiff.height();
    //       if (canvas) {
            
    //       }
    //     };
    //   })(file);
    //   reader.readAsArrayBuffer(file);
    // }
  
  function radioClear(value){
    
    
    if(value === undefined){
      //document.getElementById("file_name").value = "";
    }else{
      document.getElementById("file_name").value = value.name;
    }
  }
  function imageView(img, e) {
    return new Promise((resolve, reject) => {
      //let img = new Image()
      img.onload = () => resolve(img.height)
      img.onerror = reject
      img.src = e.target.result;
      console.log("width :"+img.width);
      console.log("height :"+img.height)
      // ctx.drawImage(img, 0, 0, img.width, img.height)
    })
  }
  return (
    <section className="container sub_container">
      <button
        className="btn_lnb_open"
        onClick={() => {
          if (slide) {
            document.querySelector(".lnb").style.display = "block";
            document.querySelector(".btn_lnb_open").classList.add("on")
            setSlide(false);
            body.style.overflow = "hidden";
          } else {
            document.querySelector(".lnb").style.display = "none";
            document.querySelector(".btn_lnb_open").classList.remove("on")
            setSlide(true);
            body.style.overflow = "";
          }
        }}
      >Pose Estimation</button>
      <DemoSideMenu path={path}></DemoSideMenu>
      <div className="subContent">
        <div className="sub_top_title">
          <div className="mb_24">
            <h1>Pose Estimation</h1>
            <div className="right_btn">
              <Link to="/poseImageDocument" className="btn">
                API Document
              </Link>
            </div>
          </div>

          <p className="txtType_1">
            이미지를 분석해 사람의 머리, 눈, 코, 손목, 무릎 등 25개의 관절 포인트를 추출하는 기능을 제공합니다. 

          </p>
          <div className="tooltip">
          최대 용량 3MB / 해상도 640 ~ 1024 pixel인 이미지만 분석이 가능합니다. 회전된 이미지, 정면이 아닌 이미지는 자세가 제대로 추출되지 않을 수 있습니다. 
          jpg, bmp, png 확장자 이외의 이미지는 결과가 제대로 추출되지 않을 수 있습니다.
          </div>
        </div>

        <h2 className="titleType_1">요청</h2>
        <div className="listType_4">
          <ul>
            <li>
              <label htmlFor="appKey" className="title">
                appKey
              </label>
              <input type="text" id="appKey" ref={inputTextTag} />
            </li>
            <li>
            <span className="title">File</span>
                <div className="right_btn">
                  <input type="text" id="file_name" disabled/>
                  <input type="file" id="file_upload" accept=".jpg, .png, .tiff" style={{ display: "none" }} ref={inputFileTag} onChange={(e)=> radioClear(e.target.files[0])}/>
                  <label htmlFor="file_upload" className="btn">
                    Upload
                  </label>
              </div>
            </li>
            
          </ul>
        </div>
        <div className="txt_r">
          <input
            type="submit"
            value="전송"
            className="basic-btn01 btn-red-bg"
            onClick={analzer}
          />
        </div>
      </div>
      {state === undefined ? (
        <></>
      ) : state === "Success" ? (
        <div className="sub_box">
          <div className="title_area">
            <h2>응답</h2>
          </div>
          <div className="sub_box_content">
            <h3 className="titleType_2">분석 결과</h3>
            <div className="img_list">
            <div style={{display:"none"}}>
                    <img ref={imageTag} />
            </div>
            <ul>
                <li>
                  <div className="img_area">
                    <canvas
                      ref={canvasTag}
                       style={{ width: canvasWidth}}
                      //style={{ height: {height}, width : {width} }}
                      className="canvas"
                    ></canvas>
                  </div>
                  <div className="txtArea">분석 이미지</div>
                </li>
              </ul>
              <h3 className="titleType_2">Meta in json</h3>
              <div className="codeArea">
                    <SyntaxHighlighter
                      language="json"
                      style={a11yDark}
                      showLineNumbers
                    >
                      {`${JSON.stringify(postDataMeta, null, "  ")}`}
                    </SyntaxHighlighter>
              </div>
            </div>
            


            <h3 className="titleType_4 big">End2End API</h3>
              <div className="top_right_btn_area">
                <h4 className="titleType_2">Request</h4>
                <div className={`state ` + postDatastate}>
                  <span className="number">200</span>
                  <div className="txt">{state}</div>
                </div>
              </div>
              
              <div className="codeArea mb_16">
                  <SyntaxHighlighter
                    language="json"
                    style={a11yDark}
                    showLineNumbers
                  >
                    {`${JSON.stringify({requestData}, null, "  ")}`}
                  </SyntaxHighlighter>
              </div>

              <div className="top_right_btn_area">
                <h4 className="titleType_2">Response</h4>
              </div>
              <div className="codeArea">
                  <SyntaxHighlighter
                    language="json"
                    style={a11yDark}
                    showLineNumbers
                  >
                    {`${JSON.stringify(postDataResponse, null, "  ")}`}
                  </SyntaxHighlighter>
              </div>
          </div>
        </div>
      ) : (
        <div className="sub_box">
          <div className="title_area">
            <h2>응답</h2>
          </div>
          <div className="sub_box_content">
            <h3 className="titleType_2">분석 결과</h3>

            <div className="analysis_result">
              <div className="no_result">
                <p className="error_txt">
                  이미지가 분석되지 않았습니다. <br />
                  다시 시도해 주십시오.
                  <span>{`(Response Code :${errorState})`}</span>
                </p>
              </div>
            </div>

            <div className="top_right_btn_area">
                <h4 className="titleType_2">Meta in json</h4>
            </div>
            {/* <div className="top_right_btn_area">
              <h4 className="titleType_2">Response</h4>
              <div
                className={`state ` + statusCodes[httpResult.getResult.Status]}
              >
                <span className="number">200</span>
                <div className="txt">{httpResult.getResult.Status}</div>
              </div>
            </div> */}
            <div className="codeArea mb_50">
            <SyntaxHighlighter
                language="json"
                style={a11yDark}
              >
                {`{}`}
              </SyntaxHighlighter>
            </div>
            <h3 className="titleType_4 big">End2End API</h3>
              <div className="top_right_btn_area">
                <h4 className="titleType_2">Request</h4>
                <div className={`state red`}>
                  <span className="number">{errorState}</span>
                  <div className="txt">{errorText}</div>
                </div>
              </div>
              
              <div className="codeArea mb_16">
                  <SyntaxHighlighter
                    language="json"
                    style={a11yDark}
                    showLineNumbers
                  >
                    {`${JSON.stringify({requestData}, null, "  ")}`}
                  </SyntaxHighlighter>
              </div>

              <div className="top_right_btn_area">
                <h4 className="titleType_2">Response</h4>
              </div>
              <div className="codeArea">
                  <SyntaxHighlighter
                    language="json"
                    style={a11yDark}
                    showLineNumbers
                  >
                    {`{}`}
                  </SyntaxHighlighter>
              </div>
          </div>
          
        </div>
      )}
      <ResponseInfo
        httpResult={httpResult}
        requestData={requestData}
        type="pose"
      ></ResponseInfo>
    </section>
  );
};

export default PoseEstimationImageComponent;