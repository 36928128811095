import React, { useEffect } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/hljs";

export const statusCodes = {
  Failed: "red",
  Fail: "red",
  NotCreate: "red",
  AllDone: "green",
  Created: "green",
  Success: "green"
};

export const statusCodesNumber = {
  Failed: "500",
  Fail: "400",
  NotCreate: "500",
  AllDone: "200",
  Created: "200",
  Success: "200"
};
const ResponseInfo = ({ httpResult, requestData, type, getResultData}) => {
  useEffect(() => {
    a11yDark["hljs"].height = "100%";
  }, []);
  if (httpResult === undefined) {
    return <></>;
  } else {
    const { createAnalyzer, getResult } = httpResult;
    const createRequest = requestData;
    createRequest.method = "POST";
    //createRequest.baseUrl = requestData.baseUrl + "/golf/v1";
    const getResultRequest = requestData;
    //getResultRequest.method = "GET";
    getResultRequest.baseUrl =
      requestData.baseUrl
    

    return (
      <div className="sub_box">
        <div className="title_area">
          <h2>응답정보</h2>
        </div>
        <div className="sub_box_content">
          <h3 className="titleType_4 big">CreateAnalyzer API</h3>
          <div className="top_right_btn_area">
            <h4 className="titleType_2">Request</h4>
            <div className={`state ` + statusCodes[httpResult.result.state]}>
              <span className="number">{statusCodesNumber[httpResult.result.state]}</span>
              <div className="txt">{httpResult.result.state}</div>
            </div>
          </div>
          <div className="codeArea mb_16">
            {/* {type === "golf" ? ( */}
              <SyntaxHighlighter
                language="json"
                style={a11yDark}
                showLineNumbers
              >
                {`${JSON.stringify(createRequest, null, "  ")}`}
              </SyntaxHighlighter>
            {/* ) : (
              <textarea
                style={{
                  height: "100%",
                  backgroundColor: "black",
                  color: "white",
                }}
                value={JSON.stringify(createRequest, null, "  ")}
                readOnly
              ></textarea>
            )} */}
          </div>

          <div className="top_right_btn_area">
            <h4 className="titleType_2">Response</h4>
          </div>
          <div className="codeArea mb_50">
            {/* {type === "golf" ? ( */}
              <SyntaxHighlighter
                language="json"
                style={a11yDark}
                showLineNumbers
              >
                {`${JSON.stringify(createAnalyzer, null, "  ")}`}
              </SyntaxHighlighter>
            {/* ) : (
              <textarea
                style={{
                  height: "100%",
                  backgroundColor: "black",
                  color: "white",
                }}
                value={JSON.stringify(createAnalyzer, null, "  ")}
                readOnly
              ></textarea>
            )} */}
          </div>

          <h3 className="titleType_4 big">getResult API</h3>
          <div className="top_right_btn_area">
            <h4 className="titleType_2">Request</h4>
            <div className={`state ` + statusCodes[httpResult.result.state]}>
              <span className="number">{statusCodesNumber[httpResult.result.state]}</span>
              <div className="txt">{httpResult.result.state}</div>
            </div>
          </div>
          <div className="codeArea mb_16">
            {/* {type === "golf" ? ( */}
              <SyntaxHighlighter
                language="json"
                style={a11yDark}
                showLineNumbers
              >
                {`${JSON.stringify(getResultData, null, "  ")}`}
              </SyntaxHighlighter>
            {/* ) : (
              <textarea
                style={{
                  height: "100%",
                  backgroundColor: "black",
                  color: "white",
                }}
                value={JSON.stringify(getResultRequest, null, "  ")}
                readOnly
              ></textarea>
            )} */}
          </div>

          <div className="top_right_btn_area">
            <h4 className="titleType_2">Response</h4>
          </div>
          <div className="codeArea">
            {type === "golf" ? (
              <SyntaxHighlighter
                language="json"
                style={a11yDark}
                showLineNumbers
              >
                {`${JSON.stringify(getResult, null, "  ")}`}
              </SyntaxHighlighter>
            ) : (
              <textarea
                style={{
                  height: "100%",
                  backgroundColor: "black",
                  color: "white",
                }}
                value={JSON.stringify(getResult.PoseEstimation, null, "  ")}
                readOnly
              ></textarea>
            )}
          </div>
        </div>
      </div>
    );
  }
};

export default ResponseInfo;
