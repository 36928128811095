import React from 'react';
import Loader from 'react-loader-spinner';
 
const Loading = () => {
    return (
        
            <Loader
                type="ThreeDots"
                color="#404040"
                height={80}
                widht={80}
                //timeout={10000}
            />
        
    );
  };
  
export default Loading;