import axios from "axios";
import { useState } from "react";
const RequestApi = async (type, action, swing, appKey, file, url, urlGet) => {
  const formdata = { Type: type, Action: action, Swing: swing };
  const header = { appKey: appKey, "Content-Type": "application/json" };
  const response = {
    result: {
      state: "",
      type: "",
    },
    createAnalyzer: {},
    startAnalyzer: {},
    getResult: {},
  };
  //각 단계 별로 에러 났을 때 return 해주기
  const s3FormData = await axios //create 임시 생성 성공
    .post(
      process.env.REACT_APP_API_URL + url,
      JSON.stringify(formdata),
      {
        headers: header,
      }
    )
    .then((data) => {
      console.info(data);
      return data.data; //json 형식
    })
    .catch((createError) => {
      return createError;
    });

  if (s3FormData.State === "Success") {
    // 임시 업로드 주소 생성되었을 때 업로드 프로세스
    //start
    const url = s3FormData.URL;
    const jobId = s3FormData.JobID;
    const dataParameter = requestParameter(s3FormData, file);

    await axios
      .post(url, dataParameter)
      .then((data) => {
        if (data.status === 204) {
        } else {
          response.startAnalyzer = data.data; //http 200 - fail
          return response; //data.data;
        }
      })
      .catch((startError) => {
        response.startAnalyzer = startError;
        return startError;
      });
    await getResult(jobId);
    response.createAnalyzer = s3FormData;

    return response;
  } else {
    response.createAnalyzer = s3FormData;
    response.result.type = "createAnalyzer";
    response.result.state = "Fail";
    return response;
  }

  async function getResult(jobId) {
    //result
    //결과 바로 안나와서 callback 로 몇번 시도해야함

    let result = await axios
      .get(process.env.REACT_APP_API_URL + urlGet + jobId, {
        headers: header,
      })
      .then((data) => {
        return data.data;
      })
      .catch((resultError) => {
        response.getResult = resultError;
        return response; //httpCode = 4xx || 5xx
      });

    if (result.State === "Success") {
      if (result.Status === "AllDone") {
        response.getResult = result;
        response.result.type = "getResult";
        response.result.state = "Success";
        return response; //분석 성공
      } else {
        return await sleep(5000).then(() => getResult(jobId)); //분석 중 5초 delay
      }
    } else {
      response.getResult.state = "Fail";
      response.result.type = "getResult";
      response.result.state = "Fail";
      return response; // httpCode = 200 / 분석 실패
    }
  }

  function sleep(ms) {
    return new Promise((r) => setTimeout(r, ms));
  }

  function requestParameter(s3FormData, file) {
    const s3Data = new FormData();
    const dataParameter = s3FormData.Form;
    s3Data.append("key", dataParameter["key"]);
    s3Data.append("policy", dataParameter["policy"]);
    s3Data.append("x-amz-algorithm", dataParameter["x-amz-algorithm"]);
    s3Data.append("x-amz-credential", dataParameter["x-amz-credential"]);
    s3Data.append("x-amz-date", dataParameter["x-amz-date"]);
    s3Data.append(
      "x-amz-security-token",
      dataParameter["x-amz-security-token"]
    );
    s3Data.append("x-amz-signature", dataParameter["x-amz-signature"]);
    s3Data.append("File", file);
    return s3Data;
  }
};

export default RequestApi;
