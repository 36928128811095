import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { SlideDown } from "react-slidedown";
import "react-slidedown/lib/slidedown.css";
import { sliderOnOff, search } from "../js/sideMenuFunction";
const DemoSideMenu = ({ path }) => {
  const [keyword, setKeyword] = useState("");
  
  const [exposeMenu, setExposeMenu] = useState([
    {
      parantMenu: "Video API",
      childMenu: [
        { category: "Golf Swing Analyzer", url: "/golfSwingVideo" },
        {
          category: "Pose Estimation",
          url: "/poseEstimationVideo",
        },
      ],
      type: "video",
    },
    {
      parantMenu: "Image API",
      childMenu: [
        { category: "Golf Swing Analyzer", url: "/golfSwingImage" },
        { category: "Pose Estimation", url: "/poseEstimationImage" },
        { category: "License Plate Recognition", url: "/lprImage" },
      ],
      type: "image",
    },
    {
      parantMenu: "Video API (MEC)",
      childMenu: [{ category: "Golf Swing Analyzer", url:"/mecCloud"}],
      type: "mec",
    },
  ]);

  const [initMenu, setInitMenu] = useState(exposeMenu);
  const [video, setVideo] = useState(false);
  // const videoTag = useRef(undefined);
  const [image, setImage] = useState(false);
  // const imageTag = useRef(undefined);
  const [mec, setMec] = useState(false);
  // const mecTag = useRef(undefined);

  useEffect(() => {}, [exposeMenu]);
  function closeYn(type) {
    if (type === "image") {
     
      return image;
    } else if (type === "video") {
      
      return video;
    } else if (type === "mec") {
      
      return mec;
    }
  }
  function slideYn(type) {
    const tag = document.getElementById(type);
    if (type === "image") {
      setImage(!image);
      sliderOnOff(image, tag);
      
    } else if (type === "video") {
      setVideo(!video);
      sliderOnOff(video, tag);
      
    } else if (type === "mec") {
      setMec(!mec);
      sliderOnOff(mec, tag);
      
    }
    
  }
  return (
    <div className="lnb">
      <div className="search_area">
        <input
          type="text"
          placeholder="검색"
          onKeyUp={(event) => {
            // if()
            if (event.key === "Enter") {
              setExposeMenu(search(keyword, initMenu));
            }
            setKeyword(event.target.value);
          }}
        />
        {/* <button 
          type="button" 
          class="btn_text_delete" 
          style="opacity: 0;">
            글자삭제
        </button> */}
        <button
          type="button"
          className="btn_search"
          onClick={() => setExposeMenu(search(keyword, initMenu))}
        >
          검색시작
        </button>
      </div>
      <div className="lnb_menu">
        <ul>
          {exposeMenu.map((menu, index) => (
            <li key={menu.type + index} className="lnb_on" id={menu.type}>
              <a
                href="#"
                className="open_slnb"
                onClick={(dom) => {
                  slideYn(menu.type);
                }}
              >
                {menu.parantMenu}
              </a>
              <SlideDown closed={closeYn(menu.type)} transitionOnAppear={false}>
                <div className="s_lnb">
                  <ul>
                    {menu.childMenu.map((subMenu, index) => (
                      <li key={subMenu.url}>
                        <Link
                          to={subMenu.url}
                          className={path === subMenu.url ? "on" : ""}
                        >
                          {subMenu.category}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </SlideDown>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DemoSideMenu;
