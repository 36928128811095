import React, { useEffect, useRef, useState } from "react";
import RequestApi from "../../RequestApi";
import DemoSideMenu from "../../common/sideMenu/DemoSideMenu";
import { Link , useHistory } from "react-router-dom";
import { getInfo } from "react-mediainfo";
import ResponseInfo from "../../common/response/ResponseInfo";
import SyntaxHighlighter from "react-syntax-highlighter";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import axios from "axios";
import Loader from 'react-loader-spinner'
import Loading from '../../loading';
const MecCloudComponent = (match) => {
  let imgCtx = undefined;
  let ctx = [];
  const [postData, setpostData] = useState(undefined);
  const body = document.querySelector("body");
  const [postDataResponse, setpostDataResponse] = useState(true);
  const [postDataMeta, setpostDataMeta] = useState(true);
  const [postDatastate, setpostDatastate] = useState(true);
  const [state, setState] = useState(undefined);
  const [slide, setSlide] = useState(true);
  const [data, setData] = useState(null);
  const [errorLog, setErrorLog] = useState(undefined);
  const [canvasWidth, setCanvasWidth] = useState(undefined);
  const videoCanvasTag = useRef(null);
  const imgCanvasTag = useRef(null);
  const [errorState, setErrorState] = useState(undefined);
  const [errorText, setErrorText] = useState(undefined);
  const [loading, setLoading] = useState(undefined);
  const [requestData, setRequestData] = useState({
    header: {
      appKey: "",
      "Content-Type": "application/json",
    },
    body: { file: undefined },
  });
  const [metaData, setMataData] = useState(undefined);
  const [httpResult, setHttpResult] = useState(undefined);
  const inputFileTag = useRef(null);
  const inputTextTag = useRef(null);
  const inputIdTag = useRef(null);
  
  const canvasTag = useRef(null);
  const imageTag = useRef(null);
  const imageReader = new FileReader();
  
  useEffect(() => {
    if(document.querySelector(".lnb").style.display === 'block'){
      body.style.overflow = "hidden";
    }else{
      body.style.overflow = "";
    }
    a11yDark["hljs"].height = "100%";
  });
  let video = undefined;
  let img = undefined;
  let videoCtx = undefined;
  let frameRate = 0;
  let initFlag = true;
  let guidePoint = undefined;
  let guideType = "";
  const path = match.match.path;
  const formData = new FormData();
async function analzer() {
  if(document.getElementById("token").value === ''){
    alert('token을 입력해 주십시오.');
    return false;
  }else if(document.getElementById("file_name").value === ''){
    alert('파일을 업로드해 주십시오.');
    return false;
  }else{
  setState(undefined);
  setHttpResult(undefined);
  setLoading(true);
  const file = inputFileTag.current.files[0];
  const apiKey = inputTextTag.current.value;
  const deviceId = inputIdTag.current.value;
  //const img = imageTag.current;
  
  frameRate = await getInfo(file).then((media) => {
    //파일 frame 수
    return media.media.track[0].FrameRate;
  });
  
  formData.append("file", file);
  
  const lprData = await axios
    .post(
      "http://52.141.56.99:30240/visionai/syncVideo", formData,
      {
        headers: { token: apiKey,
                   deviceId : deviceId,
                   'Content-Type': 'application/json'
                 }
      },
    )
    .then((data) => {
      if (data.status === 200) {
        setpostDataResponse(data);
        setpostDataMeta(data.data.result);
        setpostDatastate(data.data.status);
        return data.data;
      } else {
      }
    })
    .catch((startError) => {
      console.info(startError);
      if(startError.response != undefined){
        setErrorState(startError.response.status)
        setErrorText(startError.response.statusText)
      }
      return startError;
    });
  //if (lprData.Result.joints.length > 0 || lprData.State !== 'Fail') {
    if (lprData.result !== undefined && lprData.result != "") {
    setState("Success");
    guidePoint = lprData.result[0]
    //setpostDataResponse(lprData.Result)
    const videoCanvas = videoCanvasTag.current; //document.querySelector(".canvas");
    const imgCanvas = imgCanvasTag.current;
    videoCtx = videoCanvas.getContext("2d");
    imgCtx = imgCanvas.getContext("2d");
    ctx.push(videoCtx, imgCtx);
    setCanvasWidth(imgCanvasTag.current.width)
    video = document.createElement("video");
    video.src = URL.createObjectURL(inputFileTag.current.files[0]);
    video.muted = true;
    video.autoplay = true;
    video.play();
    video.loop = true;
    img = document.createElement("video");
    img.src = URL.createObjectURL(inputFileTag.current.files[0]);
    img.muted = true;
    img.autoplay = true;
    video.addEventListener("canplaythrough", render);
    
  } else if(lprData.result == ""){
    setState("Fail");
    setErrorState("500");
    setErrorText("Fail")
    
  } else {
    setState("Fail");
  }
  setRequestData({
    baseUrl: "http://52.141.56.99:30240/visionai/syncVideo",
    header: {
      token : apiKey,
      deviceId : deviceId,
      "Content-Type": "application/json",
    },
    body: {
      file: file.name
    },
  });
  setData(lprData);
  setMataData(JSON.stringify(lprData));
  setLoading(false);
}
}
function render() { //line 100~210 canvas로 이미지, 영상 표출 후 받아온 좌표를 이용해 포인트와 라인을 그리는 함수
  if (initFlag) {
    videoCanvasTag.current.height = video.videoHeight;
    videoCanvasTag.current.width = video.videoWidth;
    imgCanvasTag.current.height = video.videoHeight;
    imgCanvasTag.current.width = video.videoWidth;
    initFlag = false;
  }

  videoCtx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
  imgCtx.drawImage(img, 0, 0, video.videoWidth, video.videoHeight);
  // 머리
  
  ctx.forEach((head) => {
    head.fillStyle = "blue";
    head.beginPath();
    if(guidePoint.head_center[0] > 0 && guidePoint.head_center[1] > 0 && guidePoint.head_circle_radius > 0){
      head.arc(
        guidePoint.head_center[0],
        guidePoint.head_center[1],
        guidePoint.head_circle_radius,
        0,
        2 * Math.PI
      ); //head
      head.closePath();
      head.stroke();
    }
  });
  
  videoCtx.fillStyle = "red";
  imgCtx.fillStyle = "red";
  // guidePoint.joints.forEach((joint) => {
  //   //joint
  //   videoCtx.fillRect(joint[0], joint[1], 5, 5);
  //   imgCtx.fillRect(joint[0], joint[1], 5, 5);
  // });

    if(guidePoint.body_line_left_top_point[0] > 1 || guidePoint.body_line_left_bottom_point[1] > 1){
      drawGuideline(
        guidePoint.body_line_left_top_point,
        guidePoint.body_line_left_bottom_point,
        "green",
        "8"
      ); //body left
    }
    if(guidePoint.body_line_right_top_point[0] > 1 || guidePoint.body_line_right_bottom_point[1] > 1){
      drawGuideline(
        guidePoint.body_line_right_top_point,
        guidePoint.body_line_right_bottom_point,
        "green",
        "8"
      ); // body right
    }
    if(guidePoint.shoulder_line_left_point[0] > 1 || guidePoint.shoulder_line_right_point[1] > 1){
      drawGuideline(
        guidePoint.shoulder_line_left_point,
        guidePoint.shoulder_line_right_point,
        "blue",
        "8"
      ); // shoulder
    }
    if(guidePoint.hip_ankle_line_lefttop_point[0] > 1 || guidePoint.hip_ankle_line_leftbottom_point[1] > 1){
      drawGuideline(
        guidePoint.hip_ankle_line_lefttop_point,
        guidePoint.hip_ankle_line_leftbottom_point,
        "blue",
        "8"
      ); // ankle left
    }
    if(guidePoint.hip_ankle_line_rightbottom_point[0] > 1 || guidePoint.hip_ankle_line_rightbottom_point[1] > 1){
      drawGuideline(
        guidePoint.hip_ankle_line_righttop_point,
        guidePoint.hip_ankle_line_rightbottom_point,
        "blue",
        "8"
      ); // ankle right 
    }
  

  requestAnimationFrame(render); //영상 재생
}

  function drawGuideline(startPoint, endPoint, color, lineWidth) {
    // 곡선 , 원 , 포인트
    
    ctx.forEach((line) => {
      line.fillRect(startPoint[0], startPoint[1], 10, 10);
      line.fillRect(endPoint[0], endPoint[1], 10, 10);
      line.strokeStyle = color;
      line.lineWidth = lineWidth;
      line.beginPath();
      line.moveTo(startPoint[0], startPoint[1]);
      line.lineTo(endPoint[0], endPoint[1]);
      line.closePath();
      line.stroke();
    });
    
  }

  function drawBallLine(startPoint, ballPoint, color, lineWidth) {
    //full_back 일때만 공이랑 상체랑 연결
    
      imgCtx.fillRect(startPoint[0], startPoint[1], 10, 10);
      imgCtx.fillRect(ballPoint[0], ballPoint[1], 10, 10);
      imgCtx.strokeStyle = color;
      imgCtx.lineWidth = lineWidth;
      imgCtx.beginPath();
      imgCtx.moveTo(startPoint[0], startPoint[1]);
      imgCtx.lineTo(ballPoint[0], ballPoint[1]);
      imgCtx.closePath();
      imgCtx.stroke();
  
  }
  function radioClear(value,src){
    if(value === undefined){
      //document.getElementById("file_name").value = "";
    }else{
      document.getElementById("file_name").value = value.name;
    }
  }
  function imageView(img, e) {
    return new Promise((resolve, reject) => {
      //let img = new Image()
      img.onload = () => resolve(img.height)
      img.onerror = reject
      img.src = e.target.result;
      console.log("width :"+img.width);
      console.log("height :"+img.height)
      // ctx.drawImage(img, 0, 0, img.width, img.height)
    })
  }
  return (
    <section className="container sub_container">
      <button
        className="btn_lnb_open"
        onClick={() => {
          if (slide) {
            document.querySelector(".lnb").style.display = "block";
            document.querySelector(".btn_lnb_open").classList.add("on")
            setSlide(false);
            body.style.overflow = "hidden";
          } else {
            document.querySelector(".lnb").style.display = "none";
            document.querySelector(".btn_lnb_open").classList.remove("on")
            setSlide(true);
            body.style.overflow = "auto";
          }
        }}
      >
        Video API (MEC)
      </button>
      <DemoSideMenu path={path}></DemoSideMenu>
      <div className="subContent">
        <div className="sub_top_title">
          <div className="mb_24">
            <h1>Golf Swing Analyzer</h1>
            <div className="right_btn">
              <Link to="/golfImageDocument" className="btn">
                API Document
              </Link>
            </div>
          </div>

          <p className="txtType_1">
          SKT Pose API 를 이용하여 실내 스크린 골프 존 내 스윙 영상을 분석해서 골프 스윙 준비 자세를 추출하고, 준비 자세가 알맞도록 사람의 머리, 관절, 골프 공 등을 추출해 Guideline 을 제공합니다.
          </p>
          <div className="tooltip">
          최대 용량 10MB / 해상도 640 ~ 1024 pixel인 영상만 분석이 가능하며, 4초 이상의 영상에 대한 초기 4초의 한번 스윙에 대해 분석이 가능합니다. 또한 스크린 골프장 영상에 적합하게 설계되어, 야외 영상 및 한 명 기준의 골프 스윙이 아닌 영상, 회전된 영상 등은 준비 자세가 제대로 추출되지 않을 수 있습니다.
          mp4, mov 확장자 이외의 영상은 결과가 제대로 추출되지 않을 수 있습니다.
          </div>
        </div>

        <h2 className="titleType_1">요청</h2>
        <div className="listType_4">
          <ul>
            <li>
              <label htmlFor="token" className="title">
               token
              </label>
              <input type="text" id="token" ref={inputTextTag}  />
            </li>
            <li>
              <label htmlFor="deviceId" className="title">
              deviceId
              </label>
              <input type="text" id="deviceId" ref={inputIdTag} />
            </li>
            <li>
              <span className="title">File</span>
              <div className="right_btn">
                <input type="text" id="file_name" disabled/>
                <input type="file" id="file_upload" style={{ display: "none" }} ref={inputFileTag} onChange={(e)=> radioClear(e.target.files[0],e.target)}/>
                <label htmlFor="file_upload" className="btn">
                  Upload
                </label>
              </div>
            </li>
            
          </ul>
        </div>
        <div className="txt_r">
          <input
            type="submit"
            value="전송"
            className="basic-btn01 btn-red-bg"
            onClick={analzer}
          />
        </div>
      </div>
      {state === undefined ? (
        <></>
      ) : state === "Success" ? (
        <div className="sub_box">
          <div className="title_area">
            <h2>응답</h2>
          </div>
          <div className="sub_box_content">
            <h3 className="titleType_2">분석 결과</h3>
            <div className="img_list">
            <div style={{display:"none"}}>
                    <img ref={imageTag} />
            </div>
            <ul>
                <li>
                  <div className="img_area">
                    {/* <img src="../img/img_golf_video_p.jpg" alt="" /> */}
                    <canvas
                      ref={imgCanvasTag}
                      style={{ width: canvasWidth }}
                      className="canvas"
                    ></canvas>
                  </div>
                  <div className="txtArea">분석 이미지</div>
                </li>
                <li>
                  <div className="img_area">
                    {/* <img src="../img/img_golf_image_p.jpg" alt="" /> */}
                    <canvas
                      ref={videoCanvasTag}
                      style={{ width: canvasWidth }}
                      className="canvas"
                    ></canvas>
                  </div>
                  <div className="txtArea">분석 영상</div>
                </li>
              </ul>
            <h3 className="titleType_2">Meta in json</h3>
            <div className="codeArea">
                  <SyntaxHighlighter
                    language="json"
                    style={a11yDark}
                    showLineNumbers
                  >
                    {`${JSON.stringify(postDataMeta, null, "  ")}`}
                  </SyntaxHighlighter>
            </div>  
          </div>
          <h3 className="titleType_4 big">End2End API</h3>
            <div className="top_right_btn_area">
              <h4 className="titleType_2">Request</h4>
              <div className={`state ` + postDatastate}>
                <span className="number">{postDatastate}</span>
                <div className="txt">{state}</div>
              </div>
            </div>
            <div className="codeArea mb_16">
                <SyntaxHighlighter
                  language="json"
                  style={a11yDark}
                  showLineNumbers
                >
                  {`${JSON.stringify({requestData}, null, "  ")}`}
                </SyntaxHighlighter>
            </div>

            <div className="top_right_btn_area">
              <h4 className="titleType_2">Response</h4>
            </div>
            <div className="codeArea">
                <SyntaxHighlighter
                  language="json"
                  style={a11yDark}
                  showLineNumbers
                >
                  {`${JSON.stringify(postDataResponse, null, "  ")}`}
                </SyntaxHighlighter>
            </div>
        </div>
      </div>
      ) : (
        <div className="sub_box">
          <div className="title_area">
            <h2>응답</h2>
          </div>
          <div className="sub_box_content">
            <h3 className="titleType_2">분석 결과</h3>

            <div className="analysis_result">
              <div className="no_result">
                <p className="error_txt">
                  이미지가 분석되지 않았습니다. <br />
                  다시 시도해 주십시오.
                  <span>(Response Code : {errorState})</span>
                </p>
              </div>
            </div>

            <div className="top_right_btn_area">
              <h4 className="titleType_2">Meta in json</h4>
             
            </div>
            <div className="codeArea mb_50">
            <SyntaxHighlighter
                language="json"
                style={a11yDark}
              >
               {`{}`}
              </SyntaxHighlighter>
            </div>
            <h3 className="titleType_4 big">End2End API</h3>
              <div className="top_right_btn_area">
                <h4 className="titleType_2">Request</h4>
                <div className={`state red`}>
                  <span className="number">{errorState}</span>
                  <div className="txt">{errorText}</div>
                </div>
              </div>
              
              <div className="codeArea mb_16">
                  <SyntaxHighlighter
                    language="json"
                    style={a11yDark}
                    showLineNumbers
                  >
                    {`${JSON.stringify({requestData}, null, "  ")}`}
                  </SyntaxHighlighter>
              </div>

              <div className="top_right_btn_area">
                <h4 className="titleType_2">Response</h4>
              </div>
              <div className="codeArea">
                  <SyntaxHighlighter
                    language="json"
                    style={a11yDark}
                    showLineNumbers
                  >
                    {`{}`}
                  </SyntaxHighlighter>
              </div>
          </div>
        </div>
      )}
      <ResponseInfo
        httpResult={httpResult}
        requestData={requestData}
      ></ResponseInfo>
      <div className="loading">{loading? <Loading></Loading> : ""}</div>
    </section>
  );
};

export default MecCloudComponent;
