import React, { useEffect, useRef, useState } from "react";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import DemoSideMenu from "../../common/sideMenu/DemoSideMenu";
import { Link } from "react-router-dom";
import ResponseInfo from "../../common/response/ResponseInfo";
import axios from "axios";
import kpop from "../../common/js/kpop";
import SyntaxHighlighter from "react-syntax-highlighter";
const LprImageComponents = (match) => {
  const [state, setState] = useState(undefined);
  const [slide, setSlide] = useState(true);
  const [postData, setpostData] = useState(undefined);
  const [postDataResponse, setpostDataResponse] = useState(true);
  const [postDataMeta, setpostDataMeta] = useState(true);
  const [LPR, setLPR] = useState(true);
  const [postDatastate, setpostDatastate] = useState(true);
  const kpop = require('kpop');
  const [canvasWidth, setCanvasWidth] = useState(undefined);
  const [canvasHeight, setCanvasHeight] = useState(undefined);
  
  const body = document.querySelector("body");
  const [requestData, setRequestData] = useState({
    header: {
      appKey: "",
      "Content-Type": "application/json",
    },
    body: { file: undefined },
  });
  const [metaData, setMataData] = useState(undefined);
  const [httpResult, setHttpResult] = useState(undefined);
  const [errorState, setErrorState] = useState(undefined);
  const [errorText, setErrorText] = useState(undefined);

  const inputFileTag = useRef(null);
  const inputTextTag = useRef(null);
  const canvasTag = useRef(null);
  const imageTag = useRef(null);
  const imageReader = new FileReader();
  let ctx = undefined;
  let lprnum = undefined;
  let lprPoint = undefined;
  const path = match.match.path;
  useEffect(() => {
    if(document.querySelector(".lnb").style.display === 'block'){
      body.style.overflow = "hidden";
    }else{
      body.style.overflow = "";
    }
    
    //body.style.overflow = "";
    a11yDark["hljs"].height = "100%";
  });

  const [data, setData] = useState(null);
  function imageView(img, e) {
    img.src = e.target.result;
  }

  // async function addImageProcess(img, e){
  //   //let img= new Image();
  //   img.src = e.target.result;
  //   img.onload= async function(){
  //     //this.height;
  //     console.log(this.height);
  //     console.log(this.width);
  //     await ctx.drawImage(img, 0, 0, this.width, this.height);
  //   }
  // }
  function addImageProcess(img,e){
    return new Promise((resolve, reject) => {
      //let img = new Image()
      img.onload = () => resolve(img.height)
      img.onerror = reject
      img.src = e.target.result;
      console.log("width :"+img.width);
      console.log("height :"+img.height)
      // ctx.drawImage(img, 0, 0, img.width, img.height)
    })
  };


  async function analzer() {
    if(document.getElementById("appKey").value === ''){
      alert('appKey를 입력해 주십시오.');
      return false;
    }else if(document.getElementById("file_name").value === ''){
      alert('파일을 업로드해 주십시오.');
      return false;
    }else{
    setState(undefined);
    setHttpResult(undefined);
    const file = inputFileTag.current.files[0];
    const apiKey = inputTextTag.current.value;
    const formData = new FormData();
    formData.append("File", file);

    const lprData = await axios
      .post(
        process.env.REACT_APP_API_URL + "/lpr/v1",
        formData,
        {
          headers: { appKey: apiKey },
        }
      )
      .then((data) => {
        if (data.status === 200) {
          setpostData(data.data);
          setpostDatastate(data.status);
          return data.data;
        } else {
        }
      })
      .catch((startError) => {
        console.info(startError);
        setErrorState(startError.response.status)
        setErrorText(startError.response.statusText)
        setpostData(startError);
        return startError;
      });
    //if (lprData.result.objects.length > 0) {
      if (lprData.result !== undefined) {
      setState("Success");
      setpostDataResponse(lprData.result);
      setpostDataMeta(lprData.result.objects);
      setLPR(kpop.hangulify(lprData.result.objects[0].lp_string));
      lprnum = kpop.hangulify('ganadara')

      lprPoint = lprData.result.objects[0].lp_keypoints;
      ctx = canvasTag.current.getContext("2d");
      
      imageReader.onload = async(e) => {
        const img = imageTag.current;
        //await imageView(img, e);
        
        await addImageProcess(img,e);


        //imageView(img, e);
        // console.log(imageTag.current)
        //  console.log(imageTag.current.height)
        //  console.log(imageTag.current.width)
        // console.log(canvasTag.current.height)
        // console.log(canvasTag.current.width)
        setCanvasWidth(canvasTag.current.width)
        setCanvasHeight(canvasTag.current.height)
        canvasTag.current.height = img.naturalHeight;
        canvasTag.current.width = img.naturalWidth;
        ctx.drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight);
        
        //ctx.fillStyle = "red";
        lprPoint.forEach((point) => {
          //ctx.fillRect(point[0]*img.naturalWidth, point[1]*img.naturalHeight, 5, 5);
        });
        
        drawGuideline(
          [lprPoint[0][0]*img.naturalWidth,lprPoint[0][1]*img.naturalHeight],
          [lprPoint[1][0]*img.naturalWidth,lprPoint[1][1]*img.naturalHeight],
          "red",
          "5"
        );
        drawGuideline(
          [lprPoint[1][0]*img.naturalWidth,lprPoint[1][1]*img.naturalHeight],
          [lprPoint[2][0]*img.naturalWidth,lprPoint[2][1]*img.naturalHeight],
          "red",
          "5"
        );
        drawGuideline(
          [lprPoint[2][0]*img.naturalWidth,lprPoint[2][1]*img.naturalHeight],
          [lprPoint[3][0]*img.naturalWidth,lprPoint[3][1]*img.naturalHeight],
          "red",
          "5"
        );
        drawGuideline(
          [lprPoint[3][0]*img.naturalWidth,lprPoint[3][1]*img.naturalHeight],
          [lprPoint[0][0]*img.naturalWidth,lprPoint[0][1]*img.naturalHeight],
          "red",
          "5"
        );

      };
      imageReader.readAsDataURL(file);
      //console.log(8(lprData.result.objects[0].lp_string));
    } else if(lprData.name === "TypeError"){
      setErrorState("400")
      setState("Fail");
    } else {
      setState("Fail");
    }
    setRequestData({
      baseUrl: process.env.REACT_APP_API_URL+"/lpr/v1/",
      header: {
        appKey: apiKey,
        "Content-Type": "application/json",
      },
      body: {
        file: file.name,
      },
    });
    setData(lprData);
    setMataData(JSON.stringify(lprData));
  }
}
  function drawGuideline(startPoint, endPoint, color, lineWidth) {
    // 곡선 , 원 , 포인트를 그리는 함수
      // ctx.fillRect(startPoint[0], startPoint[1], 10, 10);
      // ctx.fillRect(endPoint[0], endPoint[1], 10, 10);
      ctx.strokeStyle = color;
      ctx.lineWidth = lineWidth;
      ctx.beginPath();
      ctx.moveTo(startPoint[0], startPoint[1]);
      ctx.lineTo(endPoint[0], endPoint[1]);
      ctx.closePath();
      ctx.stroke();
  }
  function radioClear(value) {
    if(value === undefined){
      //document.getElementById("file_name").value = "";
    }else{
      document.getElementById("file_name").value = value.name;
    }
  }
 
  
  
  return (
    <section className="container sub_container">
      <button
        className="btn_lnb_open"
        onClick={() => {
          if (slide) {
            document.querySelector(".lnb").style.display = "block";
            document.querySelector(".btn_lnb_open").classList.add("on")
            setSlide(false);
            body.style.overflow = "hidden";
          } else {
            document.querySelector(".lnb").style.display = "none";
            document.querySelector(".btn_lnb_open").classList.remove("on")
            setSlide(true);
            body.style.overflow = "";
          }
        }}
      >
        License Plate Recognition
      </button>
      <DemoSideMenu path={path}></DemoSideMenu>
      <div className="subContent">
        <div className="sub_top_title">
          <div className="mb_24">
            <h1>License Plate Recognition</h1>
            <div className="right_btn">
              <Link to="/lprImageDocument" className="btn">
                API Document
              </Link>
            </div>
          </div>
          <p className="txtType_1">
            이미지를 분석하여 차량 번호판을 검출하고 인식하여 차량 번호를
            추출합니다.
          </p>
          <div className="tooltip">
            최대 용량 3MB / 해상도 640 ~ 1024 pixel인 이미지만 분석이 가능합니다.
            jpg, bmp, png 확장자 이외의 이미지는 결과가 제대로 추출되지 않을 수 있습니다.
          </div>
        </div>

        <h2 className="titleType_1">요청</h2>
        <div className="listType_4">
          <ul>
            <li>
              <label htmlFor="appKey" className="title">
                appKey
              </label>
              <input
                type="text"
                id="appKey"
                ref={inputTextTag}
              />
            </li>
            <li>
              <span className="title">File</span>
              <div className="right_btn">
                <input type="text" id="file_name" disabled />
                <input
                  type="file"
                  accept=".jpg, .png"
                  id="file_upload"
                  style={{ display: "none" }}
                  ref={inputFileTag}
                  onChange={(e) => radioClear(e.target.files[0])}
                />
                <label htmlFor="file_upload" className="btn">
                  Upload
                </label>
              </div>
            </li>
            
          </ul>
        </div>
        <div className="txt_r">
          <input
            type="submit"
            value="전송"
            className="basic-btn01 btn-red-bg"
            onClick={analzer}
          />
        </div>
      </div>
      {state === undefined ? (
        <></>
      ) : state === "Success" ? (
        <div className="sub_box">
          <div className="title_area">
            <h2>응답</h2>
          </div>
          <div className="sub_box_content">
            <h3 className="titleType_2">분석 결과</h3>
            <h3 className="titleType_2">차량번호 : {LPR}</h3>
            <div style={{display:"none"}}>
                    <img ref={imageTag} />
            </div>
            <div className="img_list">
              <ul>
                <li>
                  <div className="img_area">
                    <canvas
                      ref={canvasTag}
                      // style={{ width: "640px", height: "357px" }}
                      style={{ width:canvasWidth }}
                      className="canvas"
                    ></canvas>
                  </div>
                  <div className="txtArea">분석 이미지</div>
                </li>
              </ul>
              <h3 className="titleType_4">Meta in json</h3>
              <div className="codeArea">
                    <SyntaxHighlighter
                      language="json"
                      style={a11yDark}
                      showLineNumbers
                    >
                      {`${JSON.stringify(postDataMeta, null, "  ")}`}
                    </SyntaxHighlighter>
              </div>  
            </div>
            <h3 className="titleType_4 big">LPR API</h3>
          <div className="top_right_btn_area">
            <h4 className="titleType_2">Request</h4>
            <div className={`state ` + postDatastate}>
              <span className="number">200</span>
              <div className="txt">{state}</div>
            </div>
          </div>
          <div className="codeArea mb_16">
              <SyntaxHighlighter
                language="json"
                style={a11yDark}
                showLineNumbers
              >
                {`${JSON.stringify({requestData}, null, "  ")}`}
              </SyntaxHighlighter>
          </div>

          <div className="top_right_btn_area">
            <h4 className="titleType_2">Response</h4>
          </div>
          <div className="codeArea">
              <SyntaxHighlighter
                language="json"
                style={a11yDark}
                showLineNumbers
              >
                {`${JSON.stringify(postData, null, "  ")}`}
              </SyntaxHighlighter>
          </div>
            </div>
          </div>
      ) : (
        <div className="sub_box">
          <div className="title_area">
            <h2>응답</h2>
          </div>
          <div className="sub_box_content">
            <h3 className="titleType_2">분석 결과</h3>

            <div className="analysis_result">
              <div className="no_result">
                <p className="error_txt">
                  이미지가 분석되지 않았습니다. <br />
                  다시 시도해 주십시오.
                  <span>{`(Response Code :${errorState})`}</span>
                </p>
              </div>
            </div>

            <div className="top_right_btn_area">
                <h4 className="titleType_2">Meta in json</h4>
              </div>
            <div className="codeArea mb_50">
            <SyntaxHighlighter
                language="json"
                style={a11yDark}
              >
                {`{}`}
              </SyntaxHighlighter>
            </div>

            <h3 className="titleType_4 big">End2End API</h3>
              <div className="top_right_btn_area">
                <h4 className="titleType_2">Request</h4>
                <div className={`state ` + errorState}>
                  <span className="number">{errorState}</span>
                  <div className="txt">{errorText}</div>
                </div>
              </div>
              
              <div className="codeArea mb_16">
                  <SyntaxHighlighter
                    language="json"
                    style={a11yDark}
                    showLineNumbers
                  >
                    {`${JSON.stringify({requestData}, null, "  ")}`}
                  </SyntaxHighlighter>
              </div>

              <div className="top_right_btn_area">
                <h4 className="titleType_2">Response</h4>
              </div>
              <div className="codeArea">
                  <SyntaxHighlighter
                    language="json"
                    style={a11yDark}
                    showLineNumbers
                  >
                    {`{}`}
                  </SyntaxHighlighter>
              </div>
          </div>
        </div>
      )}
      <ResponseInfo
        httpResult={httpResult}
        requestData={requestData}
        type="pose"
      ></ResponseInfo>
    </section>
  );
};

export default LprImageComponents;
